import React from 'react'
import styled from '@emotion/styled'
import TermsItem from '../../components/TermsAndConditions/TermsItem';
import { setUserDetails } from "../../store/reducer/reducer";
import { useDispatch, useSelector } from "react-redux";

import { get, post } from "../../api_helper/api_helper";
import { useEffect } from 'react';
const Heading = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 43.57px;
  margin: 30px auto;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 26px;
  }
`;

const StyledLink = styled.a`
  color: #25626f; /* Styling the email link */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
function TermsAndCondition() {

  const dispatch = useDispatch();

  useEffect(() => {
      const fetchUserDetails = async () => {
        try {
          const { userProfile } = await get("/api/auth/profile-info");
          dispatch(setUserDetails(userProfile || {}));
        } catch (e) {
          console.error("Error fetching user details:", e);
        }
      };
  
      fetchUserDetails();
    }, [dispatch]);	
  return (
    <>
      <Heading>Privacy Policy</Heading>
      {information.map((item, index) => {
        return (
          <TermsItem heading={item.title} description={item.detail} count={index + 1} />
        )
      })}
      <div style={{ marginBottom: "40px" }}>&nbsp;&nbsp;</div>
    </>
  )
}

export default TermsAndCondition


const information = [
  {
    "title": "Introduction ",
    "detail": "Welcome to Shipora. We respect your privacy and are committed to protecting your personal data. This Privacy Policy outlines how we collect, use, store, and share your information."
  },
  // {
  //   "title": "Services Offered",
  //   "detail": "Shipora provides shipment services including air and cargo shipping from India to Canada. The specific terms of service for each shipment will be detailed in individual contracts or agreements at the time of booking."
  // },
  // {
  //   "title": "User Eligibility",
  //   "detail": "You must be at least 18 years old to use the App. By using the App, you represent and warrant that you meet this eligibility requirement."
  // },
  {
    title: "Data We Collect",
    detail: (<>  
    
    We collect the following information:
    
      <ul>
        <li><strong>Personal Information: </strong>Name, email address, phone number, and physical addresses (pickup and drop-off addresses).</li>
        <li><strong>Authentication Information:</strong> Mobile number (for OTP authentication), or information from Apple Login and Google Login.
        </li>
        <li><strong>Media Access:</strong> Photos taken through your device’s camera or uploaded from your gallery for package-related purposes.
        </li>
        <li><strong>Notifications:</strong> Firebase is used for sending product updates and promotional notifications.
        </li>
      </ul>
      We do not collect live location data, device details, or payment card information.

      </>

    )
  },


  {
    title: "Purpose of Data Collection ",
    detail: (
      <>
      We use your data to:

      <ul>
        <li> Authenticate your identity during login.
        </li>

        <li>
        Process and fulfill your orders.
        </li>
        <li>
        Send you notifications regarding your order status, updates, and promotions.
        </li>
        <li>Enable photo uploads for packaging purposes.
        </li>
      </ul>
      </>
    )
  },

  {
    "title": "Data Storage and Security",
    "detail": "Your data is securely stored on our AWS servers. Firebase is used for sending notifications. We implement strict security measures to protect your information against unauthorized access."
  },


  {
    "title": "Third-Party Services",
    "detail": "We use Stripe for payment processing. Stripe handles sensitive payment information directly, and we do not store or process your card details. Please refer to Stripe’s Privacy Policy for more information."
  },

  // {
  //   "title": "Data Deletion",
  //   "detail": "You have the right to request the deletion of your data. To do so, please send an email to info@shipora.io with your request. We will process your request within 14 days."
  // },

  {
    title: "Data Deletion ",
    detail: (

      <>
      You have the right to request the deletion of your data. To do so, please send an email to <StyledLink href='mailto:info@shipora.io'>info@shipora.io</StyledLink> with your request. We will process your request within 14 days.

      

      </>
    )
  },



  {
    title: "Permissions ",
    detail: (

      <>
      When using the Shipora app, you may be prompted to grant permissions:

      <ul>
        <li> <strong>Camera/Gallery Access: </strong>To capture or upload package photos.
        </li>

        <li>
        <strong> Notifications:</strong> For updates related to your orders and promotional content.
        </li>
      
      </ul>

      You can manage these permissions through your device settings at any time.

      </>
    )
  },

  {
    "title": "Changes to This Policy",
    "detail": "We may update this Privacy Policy from time to time. The latest version will always be available in the app and on our website."
  },

  // {
  //   "title": " Contact Us",
  //   "detail": "For questions or concerns, please email us at info@shipora.io."
  // },

  {
    title: "Contact Us",
    detail: (

      <>
     For questions or concerns, please email us at <StyledLink href='mailto:info@shipora.io'>info@shipora.io</StyledLink>

      

      </>
    )
  },


  // {
  //   title: "Liability and Insurance",
  //   detail: (
  //     <ul>
  //       <li> Shipora is not liable for damage or loss of shipments unless you opt for insurance coverage.</li>

  //       <li>
  //       Insurance claims must be filed as per the terms of the insurance provider.</li>
 
  //     </ul>
  //   )
  // },

  // {
  //   title: "Cancellation and Refund Policy",
  //   detail: (
  //     <ul>
  //       <li> Cancellation policies are outlined at the time of booking.</li>

  //       <li>
  //       Refunds are subject to review and are not guaranteed for cancellations made after the shipment has been processed.</li>
 
  //     </ul>
  //   )
  // },

  // {
  //   "title": "Privacy Policy",
  //   "detail": "Your use of the App is subject to our Privacy Policy. Shipora collects and uses personal data in accordance with applicable privacy laws."
  // },
  // {
  //   "title": "Intellectual Property",
  //   "detail": "All content in the App, including but not limited to text, graphics, and logos, is the intellectual property of Shipora. You may not copy, modify, or distribute this content without prior written consent."
  // },
  // {
  //   "title": "Termination",
  //   "detail": "Shipora reserves the right to suspend or terminate your access to the App at any time for violations of these Terms."
  // },
  // {
  //   "title": "Limitation of Liability",
  //   "detail": "To the fullest extent permitted by law, Shipora will not be liable for any indirect, incidental, or consequential damages arising from the use of the App or services."
  // },

  // {
  //   title: "Governing Law and Dispute Resolution",
  //   detail: (
  //     <ul>
  //       <li>These Terms are governed by the laws of Canada.</li>

  //       <li>
  //       Any disputes shall be resolved through arbitration or in courts located in: 69 Argyle Crescent, Brampton, L6P1E4, Ontario, Canada.</li>
 
  //     </ul>
  //   )
  // },

  // {
  //   "title": "Amendments to Terms",
  //   "detail": "Shipora may update these Terms periodically. Continued use of the App after changes are made constitutes acceptance of the revised Terms."
  // },
  // {
  //   title: "Contact Us",
  //   detail: (

  //     <>
  //     For questions or concerns regarding these Terms, please contact us at:
  //     <ul>
  //       <li><strong>Email:</strong> info@shipora.io</li>

  //       <li>
  //       <strong>Phone: </strong>+1 (647) 241-7111</li>
 
  //     </ul>

  //     </>
  //   )
  // },
]
