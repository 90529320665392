import { useMediaQuery } from "@mui/material";  


const EditIcon = ({ onClick, sx }) => {

    return (
      <svg  style={sx} viewBox="0 0 24 23" fill="#424545" xmlns="http://www.w3.org/2000/svg"   onClick={onClick} >
        <path
          d="M17.4247 1.20495L15.0268 3.60294H3.32768V20.3889H20.1136V8.68984L22.5116 6.29185V21.5879C22.5116 22.25 21.9748 22.7869 21.3126 22.7869H2.12868C1.4665 22.7869 0.929688 22.25 0.929688 21.5879V2.40394C0.929688 1.74176 1.4665 1.20495 2.12868 1.20495H17.4247ZM21.8945 0.126465L23.5901 1.8221L12.5685 12.8438L10.8758 12.8466L10.8728 11.1481L21.8945 0.126465Z"
          fill="#424545"
   
       
        />
      </svg>
    );
  };
  
  export default EditIcon;
  
 
  