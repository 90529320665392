import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  showSuccessModal,
  showUpdatePasswordModal,
} from "../../store/reducer/reducer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { post, put } from "../../api_helper/api_helper";
import { Typography, Grid, InputAdornment, IconButton } from "@mui/material";
import CloseModal from "../Auth/CloseModal";

const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border-radius: 16px;
  width: clamp(450px, 30%, 670px);
  padding: 20px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    width: 90%;
  }
`;

const Heading = styled.div`
  font-family: "Inter";
  font-size: 36px;
  line-height: 33.89px;
  font-weight: 600;
  text-align: center;
  margin-bottom:20px;
`;
const labelStyles = {
  display: 'block',
  marginBottom: '5px',
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 500,
  color: "#5E6363",


};
function UpdatePasswordProfileModal({ setShowUpdatePassword1, showUpdatePassword1 }) {
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const forgotPasswordInfo = useSelector(
    (state) => state.general.forgotPasswordInfo
  );
  const displayModal = useSelector(
    (state) => state.general.showUpdatePasswordModal
  );
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const { message } = await put("/api/auth/profile-info-change-password", {
        new_password: values.new_password,
        confirm_password: values.confirm_password,
        old_password: values.old_password,
      });
      setLoading(false);
      setShowUpdatePassword1(false)
      dispatch(showSuccessModal("password"));
      toast.success(message);
      formik.resetForm();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  };

  const ValidationSchema = Yup.object().shape({
    old_password: Yup.string().strict(true).required("Password is required!"),
    new_password: Yup.string()
      .strict(true)
      .required("Password is required!")
      .test("new_password", "Invalid password", (value) => {
        if (!value || value.length < 6) {
          throw new Yup.ValidationError(
            "Password should be at least 6 characters long",
            null,
            "new_password"
          );
        }
        if (!/[A-Z]/.test(value)) {
          throw new Yup.ValidationError(
            "Password should contain at least one uppercase letter",
            null,
            "new_password"
          );
        }
        if (!/[a-z]/.test(value)) {
          throw new Yup.ValidationError(
            "Password should contain at least one lowercase letter",
            null,
            "new_password"
          );
        }
        if (!/\d/.test(value)) {
          throw new Yup.ValidationError(
            "Password should contain at least one digit",
            null,
            "new_password"
          );
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
          throw new Yup.ValidationError(
            "Password should contain at least one special character",
            null,
            "new_password"
          );
        }
        return true;
      }),
    confirm_password: Yup.string()
      .strict(true)
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Please confirm your password"),
  });
  const formik = useFormik({
    validationSchema: ValidationSchema,
    initialValues: { new_password: "", confirm_password: "" },
    validateOnChange: false, // Disable validation on field change
    validateOnBlur: false, // Disable validation on field blur
    onSubmit,
  });

  return (
    <>
      <Modal
        open={showUpdatePassword1}
        onClose={() => {
          setShowUpdatePassword1(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginFormWrapper>
          <CloseModal
            closeFn={() => {
              setShowUpdatePassword1(false);
            }}
          />
          <Heading>Change Password</Heading>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>

            <Grid container spacing={2} alignItems="center">
              {/* Old Password */}
              <Grid item xs={12}>
                <Typography component="label" htmlFor="old_password" sx={labelStyles}>
                  Old Password
                </Typography>
                <TextField
                  id="old_password"
                  variant="outlined"
                  color="primary"
                  type={showOldPassword ? "text" : "password"}
                  name="old_password"
                  placeholder="Old Password"
                  value={formik.values.old_password}
                  onChange={(e) => {
                    // Remove all spaces from the password field
                    const sanitizedValue = e.target.value.replace(/\s+/g, '');
                    formik.setFieldValue('password', sanitizedValue);
                  }}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.old_password)}
                  helperText={formik.touched.old_password && formik.errors.old_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowOldPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '13px',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 400,
                      fontSize: '16px',
                      '& fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.87)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'primary.main',
                      },
                    },
                    '& .MuiInputBase-input': {
                      padding: '16.5px 14px',
                    },
                  }}

                  onInput={(e) => {
                    // Prevent spaces dynamically while typing
                    e.target.value = e.target.value.replace(/\s+/g, '');
                  }}
                />
              </Grid>

              {/* New Password */}
              <Grid item xs={12}>
                <Typography component="label" htmlFor="new_password" sx={labelStyles}>
                  New Password
                </Typography>
                <TextField
                  id="new_password"
                  variant="outlined"
                  color="primary"
                  type={showNewPassword ? "text" : "password"}
                  name="new_password"
                  placeholder="New Password"
                  value={formik.values.new_password}
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(/\s+/g, '');
                    formik.setFieldValue('new_password', sanitizedValue);
                  }}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.new_password)}
                  helperText={formik.touched.new_password && formik.errors.new_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '13px',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 400,
                      fontSize: '16px',
                      '& fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.87)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'primary.main',
                      },
                    },
                    '& .MuiInputBase-input': {
                      padding: '16.5px 14px',
                    },
                  }}
                />

              </Grid>

              {/* Confirm Password */}
              <Grid item xs={12}>
                <Typography component="label" htmlFor="confirm_password" sx={labelStyles}>
                  Confirm Password
                </Typography>
                <TextField
                  id="confirm_password"
                  variant="outlined"
                  color="primary"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm_password"
                  placeholder="Confirm Password"
                  value={formik.values.confirm_password}
                  onChange={(e) => {
                    // Remove all spaces from the password field
                    const sanitizedValue = e.target.value.replace(/\s+/g, '');
                    formik.setFieldValue('confirm_password', sanitizedValue);
                  }}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.confirm_password)}
                  helperText={
                    formik.touched.confirm_password && formik.errors.confirm_password
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowConfirmPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '13px',
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 400,
                      fontSize: '16px',
                      '& fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.87)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'primary.main',
                      },
                    },
                    '& .MuiInputBase-input': {
                      padding: '16.5px 14px',
                    },
                  }}
                />
              </Grid>





              {/* Submit Button */}
              <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={false}
                  style={{
                    marginTop: "20px",
                    background: "#00B4D8",
                    color: "white",
                    borderRadius: "8px",
                    height: "43px",
                    fontFamily: "Inter",
                  }}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        </LoginFormWrapper>
      </Modal>
    </>
  );
}

export default UpdatePasswordProfileModal;
