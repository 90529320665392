import React from "react";
import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import { useMediaQuery } from '@mui/material';
import { AspectRatio } from "@mui/icons-material";
import sliderimg from "../../assets/images/slider_img.png"
import "./static.css"
const WidthAdjuster = styled.div`
  width: 80%;
  max-width:1525px;
  margin: auto;
  margin-top: 50px;
     @media (max-width: 600px) {
   width: 95%;
     margin-top: 20px;
  }

`;

function AdViewer({ ads, offerBannerClickHandler }) {
  const isSmallScreen = useMediaQuery('(max-width: 900px)');  // Media query for max-width 900px
  // Sort ads by offerIndex
  const sortedAds = [...ads].sort((a, b) => a.offerIndex - b.offerIndex);
  const imageStyle = {
    width: "100%",
    height: "auto",
    borderRadius: "8px", // Optional: for rounded corners
    ...(isSmallScreen && {AspectRatio:"16/8.1" })  // If the screen is smaller than 900px, set the width to 80%
  };

  // Log the sorted ads array

  return (
    <WidthAdjuster>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} justifyContent={"center"}>
          {sortedAds.map((item) => {
            return (
              <Grid
                key={item.id}
                item
                xs={6} // Full width on extra small screens
                sm={6} // Half width on small screens
                md={6}  // One-third width on medium screens
                lg={6}  // One-fourth width on large screens
                style={{ cursor: "pointer" }}
                onClick={() => {
                  offerBannerClickHandler(item);
                }}
              >
                <img
                  src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${item?.image_url}`}
                  alt="ad"
                className="responsive-image"
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </WidthAdjuster>
  );
}

export default AdViewer;
