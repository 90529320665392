import React from "react";
import styled from "@emotion/styled";
import TermsItem from "../../components/TermsAndConditions/TermsItem";
import PolicyItem from "../../components/PrivacyPolicy/PolicyItem";
import Title from "../../components/PrivacyPolicy/Title";
import { setUserDetails } from "../../store/reducer/reducer";
import { useDispatch, useSelector } from "react-redux";
import { get, post } from "../../api_helper/api_helper";
import { useEffect } from "react";
const Heading = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  line-height: 43.57px;
  margin: 30px auto;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 26px;
  }
`;

const Paratitle = styled.span`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
`;
const ParaDetail = styled.span`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
`;
const HeadingWithParaWrapper = styled.div`
  width: 80%;
  text-align: justify;
  margin: 15px auto;
`;

const ListWrapper = styled.div`
  width: 80%;
  margin: 15px auto;
  padding-left: 20px;
`;
const ListItem = styled.li`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #595959;
`;
const TextItem = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #595959;
  width: 80%;
  margin: 10px auto;

   a {
    color: #25626f; /* Styling the email link */
    text-decoration: none; /* Removing underline */
    
    &:hover {
      text-decoration: underline; /* Adding underline on hover */
    }
  }
`;

const StyledLink = styled.a`
  color: #25626f; /* Styling the email link */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;


function  PrivacyPolicy() {

  const dispatch = useDispatch();

  useEffect(() => {
      const fetchUserDetails = async () => {
        try {
          const { userProfile } = await get("/api/auth/profile-info");
          dispatch(setUserDetails(userProfile || {}));
        } catch (e) {
          console.error("Error fetching user details:", e);
        }
      };
  
      fetchUserDetails();
    }, [dispatch]);	


  return (
    <>
      <Heading>Terms and Conditions</Heading>
      <div style={{ marginBottom: "40px" }}>&nbsp;&nbsp;</div>

      {/* <PolicyItem
        description={`At Shipora, we value your privacy and are committed to protecting your personal data. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use the Shipora app ("App"). By using the App, you agree to the terms of this Privacy Policy.`}
      /> */}

      <Title heading={`1. Introduction `} />
      {/* <HeadingWithPara
        detail={` Name, address, email address, and phone number. Payment details for processing transactions.`}
        heading={`1.1. Personal Information:`}
      />
      <HeadingWithPara
        detail={` Details of the items you ship, including weight, dimensions, and destination addresses.`}
        heading={`1.2. Shipment Information:`}
      />
      <HeadingWithPara
        detail={` IP address, device type, operating system, and usage statistics.`}
        heading={`1.3. Device Information:`}
      />

      <HeadingWithPara
        detail={` Real-time location data for shipment tracking, if you grant permission.`}
        heading={`1.4. Location Data:`}
      />

      <HeadingWithPara
        detail={` Any information you voluntarily provide through support requests or feedback.`}
        heading={`1.5. Other Information:`}
      /> */}

{/* <ListBox arr={List5} /> */}
<TextItem>
These Terms and Conditions govern the use of the Shipora app. By using our services, you agree to comply with these terms.

      </TextItem>
      <Title heading={`2. User Obligations`} />
      <ListBox arr={List1} />
      <Title heading={`3.  Data Handling`} />
      {/* <TextItem>
        3.1. We do not sell or rent your personal data. However, we may share your information with:
      </TextItem> */}

      

      
      <ListBox arr={List2} />

      <Title heading={`4. Payment Disclaimer`} />

      <TextItem>
      Shipora integrates Stripe as its payment gateway. We do not process or store any payment information. Users agree to Stripe’s Terms and Privacy Policy when making a payment.

      </TextItem>
      <Title heading={`5.  Permissions and Access`} />
      {/* <TextItem>
      We implement industry-standard security measures to protect your data. However, no method of electronic transmission or storage is completely secure, and we cannot guarantee absolute security.
      </TextItem> */}
          <ListBox arr={List3} />
      <Title heading={`6. Changes to Terms`} />
      <TextItem>
      We reserve the right to modify these terms at any time. Changes will be updated in the app and notified to users.

      </TextItem>
      {/* <ListBox arr={List3} /> */}

      <Title heading={`7. Contact Us`} />
      <TextItem>
      For any concerns, email us at <a href="mailto:info@shipora.io">info@shipora.io</a>.

      </TextItem>
      
      <div style={{ marginBottom: "40px" }}>&nbsp;&nbsp;</div>
    </>
  );
}

function HeadingWithPara({ heading, detail }) {
  return (
    <HeadingWithParaWrapper>
      {" "}
      <Paratitle>{heading}</Paratitle> <ParaDetail>{detail}</ParaDetail>
    </HeadingWithParaWrapper>
  );
}

function ListBox({ arr }) {
  return (
    <ListWrapper>
      {arr.map((el) => {
        return <ListItem>{el}</ListItem>;
      })}
    </ListWrapper>
  );
}
export default PrivacyPolicy;

const List1 = [
  "Provide accurate and complete information for order processing.",
  "Ensure all photos uploaded are relevant to the packaging process.",
  "Comply with applicable local laws when using our services.",

];

const List2 = [
  <>User data is stored securely on our AWS servers.
</>,
  <>Requests for data deletion can be made via email to <StyledLink href="mailto:info@shipora.io">info@shipora.io</StyledLink>
</>,
  <>All payment-related data is processed directly by Stripe.
</>,

];

const List3 = [
  "Users must grant required permissions (camera, gallery, notifications) for a seamless experience.",
  "All permissions can be managed via your device settings.",


];

const List4 = [
  <><strong>Email:</strong>info@shipora.com</>,
  <><strong>Phone: </strong> +1 (647) 241-7111</>,
  // <><strong>Opt-Out:</strong> Opt-out of receiving promotional communications.</>,
];


const List5 = [
  <><strong>Personal Information:</strong>Name, address, email address, and phone number. Payment details for processing transactions.</>,
  <><strong>Shipment Information:</strong> Details of the items you ship, including weight, dimensions, and destination addresses.  </>,
  <><strong>Device Information:</strong> IP address, device type, operating system, and usage statistics..</>,
  <><strong>Location Data:</strong> Real-time location data for shipment tracking, if you grant permission.</>,
  <><strong>Other Information:</strong> Any information you voluntarily provide through support requests or feedback.</>
];
