import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CountryCodes from "../../assets/json/countrycode.json";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import { get, post } from "../../api_helper/api_helper";
import { Autocomplete, Checkbox, Grid, TextField, Typography, InputAdornment, Divider } from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectedDeliveryAddress } from "../../store/reducer/reducer";
import { useNavigate } from "react-router-dom";
import SingleAddressitem from "./SingleAddressitem";


import { setUserDetails } from "../../store/reducer/reducer";

import axios from 'axios';
const AddressFormWrapper = styled.div`
  width: 60%;
  margin: auto;
  box-shadow: 0px 4px 30px 0px #42424229;
  background: white;
  padding-bottom: 30px;

  @media (max-width: 800px) {
    width: 97%;
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 29.05px;
  padding: 40px;
`;

const WidthAdjuster = styled.div`
  width: 90%;
  margin: auto;
`;

const ContinueButton = styled.button`
  width: 100%;
  height: 52px;
  background: #00b4d8;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  border-radius: 8px;
  border: none;
  outline: none;
  margin: 20px auto;
  cursor: pointer;
`;
// const Divider = styled.div`
//   border: 1px solid #e3e3e3;
//   width: 100%;
//   margin: 40px 0;
// `;

const HeadingSaved = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
`;

const labelStyles = {
  display: 'block',
  marginBottom: '5px',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 500,
  color: "#242626",
};
function AddressForm() {
  const [userAddresses, setUserAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.general.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [error, setError] = useState('');
  const geonamesUsername = 'muneer';
  const resetZipCode = () => {
    setZipCode('');
  };
  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const { deliveryAddress } = await post(`/api/create-delivery-address`, {
        ...values,
        ...(values.selectedAddress && { id: values.selectedAddress }),
        ...(user && { user_id: user?.id }),
        saveThis: true,
      });
      dispatch(selectedDeliveryAddress({ ...deliveryAddress }));
      setLoading(false);
      resetZipCode();

      formik.resetForm();
      getUserDeliveryAddress();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
    }
  };


  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { userProfile } = await get("/api/auth/profile-info");
        dispatch(setUserDetails(userProfile || {}));
      } catch (e) {
        console.error("Error fetching user details:", e);
      }
    };

    fetchUserDetails();
  }, [dispatch]);
  const validationSchema = Yup.object().shape({
    selectedAddress: Yup.string(), // Selected address is now optional
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    address: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    // zipcode: Yup.string().required("Zip code is required"),
    zipcode: Yup.string()
      .matches(
        /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
        "Invalid Canadian postal code format"
      )
      .required("Zip code is required"),
    mobile_number_country_code: Yup.string().required(
      "Country code is required"
    ),
    // mobile_number: Yup.string().required("Phone number is required"),
    mobile_number: Yup.string()
      .strict(true) // Strict mode will prevent automatic string coercion
      .required('Phone number is required!')
      .matches(/^\d+$/, 'Phone number must contain only digits') // Ensures the phone number contains only digits
      .length(10, 'Phone number must be exactly 10 digits'), // Ensures the number is exactly 10 digit
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      selectedAddress: "",
      first_name: "",
      last_name: "",
      address: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      mobile_number_country_code: "",
      mobile_number: "",
      state: "",
      country: "",
    },

    validateOnBlur: true, // Only validate on blur (when the field loses focus)
    validateOnChange: false, // Do not validate while the user is typing
    onSubmit,
  });
  async function getUserDeliveryAddress() {
    try {
      const addressInfo = await get(`/api/get-delivery-address`);
      setUserAddresses(addressInfo || []);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
      getUserDeliveryAddress();
    }
  }, []);

  // useEffect(() => {
  //   if (zipCode.length > 0) {
  //     const fetchLocationData = async () => {
  //       try {
  //         const response = await axios.get('http://api.geonames.org/postalCodeLookupJSON', {
  //           params: {
  //             postalcode: zipCode.replace(/\s+/g, ''), // Clean spaces from postal code
  //             country: 'CA', // Canada
  //             username: geonamesUsername,
  //           },
  //         });

  //         const data = response.data;
  //         if (data && data.postalcodes && data.postalcodes.length > 0) {
  //           const location = data.postalcodes[0];

  //           // Update Formik values directly
  //           formik.setFieldValue('city', location.placeName); // Set city in Formik
  //           formik.setFieldValue('state', location.adminName1); // Set state in Formik
  //         } else {
  //           setError('Postal code not found.');
  //           formik.setFieldValue('city', '');
  //           formik.setFieldValue('state', '');
  //         }
  //       } catch (err) {
  //         setError('Error fetching data.');
  //         console.error(err);
  //       }
  //     };

  //     fetchLocationData();
  //   }
  // }, [zipCode]);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await post('/api/zipcode-delivery-address', {
          postalcode: zipCode.replace(/\s+/g, ''), // Clean spaces from postal code
        });
  
        const data = response[0];
        console.log("Check the API data", response[0]);
  
        if (data && data.placeName && data.adminName1) {
          // Update Formik values directly
          formik.setFieldValue('city', data.placeName); // Use "placeName" for city
          formik.setFieldValue('state', data.adminName1); // Use "adminName1" for state
        } else {
          // Show toast error when postal code is not found
          setError('Postal code not found.');
          formik.setFieldValue('city', '');
          formik.setFieldValue('state', '');
        }
      } catch (err) {
        setError('Error fetching data.');
        console.error(err);
      }
    };
  
    // Run fetch only if zipCode is exactly 7 characters
    if (zipCode.length === 7) {
      fetchLocationData();
    } else {
      // Reset form fields when zipCode is invalid length
      formik.setFieldValue('city', '');
      formik.setFieldValue('state', '');
    }
  }, [zipCode]);
  

  const handleZipCodeChange = (e) => {
    let value = e.target.value.toUpperCase();
  
    // Remove invalid characters
    value = value.replace(/[^A-Za-z0-9 ]/g, "");
  
    // Restrict typing based on position
    const formattedValue = value
      .split("")
      .map((char, index) => {
        if (index === 0 || index === 2 || index === 5) {
          // Allow only letters at these positions
          return /[A-Za-z]/.test(char) ? char : "";
        }
        if (index === 1 || index === 4 || index === 6) {
          // Allow only digits at these positions
          return /\d/.test(char) ? char : "";
        }
        if (index === 3) {
          // Automatically insert space after the third character
          return " ";
        }
        return "";
      })
      .join("");
  
    // Restrict to maximum 7 characters
    const finalValue = formattedValue.substring(0, 7);
  
    setZipCode(finalValue); // Update local state
    formik.setFieldValue("zipcode", finalValue); // Update Formik's value
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <WidthAdjuster>
        <Grid container spacing={2}>
          {/* <Grid item xs={6}>
            <TextField
              fullWidth
              id="first_name"
              label="First Name"
              name="first_name"
              variant="outlined"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.first_name)}
              helperText={formik.errors.first_name}
            />
          </Grid> */}

          <Grid item xs={6}>

            {/* <p>First NAme </p> */}

            <Typography component="label" htmlFor="first_name" sx={labelStyles}>
              First Name
            </Typography>
            <TextField
              fullWidth
              id="first_name"
              // label="First Name"
              name="first_name"
              variant="outlined"
              placeholder="ex. John"
              value={formik.values.first_name}
              onChange={(e) => {
                // Allow only letters and spaces in first name
                const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Regex to remove numbers and special characters
                formik.setFieldValue('first_name', value);
              }}
              error={Boolean(formik.errors.first_name)}
              helperText={formik.errors.first_name}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  borderColor: "rgba(227, 227, 227, 1)",
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>

            <Typography component="label" htmlFor="last_name" sx={labelStyles}>
              Last Name
            </Typography>
            <TextField
              fullWidth
              id="last_name"
              // label="Last Name"
              name="last_name"
              variant="outlined"
              placeholder="ex. James"
              value={formik.values.last_name}
              onChange={(e) => {
                // Allow only letters and spaces in first name
                const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Regex to remove numbers and special characters
                formik.setFieldValue('last_name', value);
              }}
              error={Boolean(formik.errors.last_name)}
              helperText={formik.errors.last_name}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  borderColor: "rgba(227, 227, 227, 1)",
                },
              }}
            />
          </Grid>
        </Grid>
        <div style={{ height: "20px" }}></div>

        <Grid container spacing={2}>
          <Grid item xs={12}>

            <Typography component="label" htmlFor="last_nameaddress" sx={labelStyles}>
              Address
            </Typography>
            <TextField
              fullWidth
              id="address"
              // label="Address"
              name="address"
              variant="outlined"
              placeholder="ex. 507 Blanshard"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.address)}
              helperText={formik.errors.address}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  borderColor: "rgba(227, 227, 227, 1)",
                },
              }}
            />
          </Grid>
        </Grid>
        <div style={{ height: "20px" }}></div>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                value={formik.values.country}
                onChange={(_, value) => formik.setFieldValue('country', value?.value || "")}
                id="combo-box-country"
                options={[{ label: 'Canada', value: 'Canada' }]}
                renderInput={(params) => (
                  <>
                    <Typography component="label" htmlFor="last_nameaddress" sx={labelStyles}>
                      Country
                    </Typography>
                    <TextField
                      {...params}
                      placeholder="ex. Canada"
                      helperText={formik.errors.country}
                      error={Boolean(formik.errors.country)}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          borderRadius: '8px',
                          borderColor: 'rgba(227, 227, 227, 1)',
                        },
                      }}
                    />
                  </>
                )}
              />
            </FormControl>
          </Grid>


          <Grid item xs={6}>
            <Typography component="label" htmlFor="zipcode" sx={labelStyles}>
              Zip Code
            </Typography>
            <TextField
              fullWidth
              id="zipcode"
              placeholder="ex. T5A 0A1"
              name="zipcode"
              variant="outlined"
              value={formik.values.zipcode || zipCode} // Bind directly to zipCode state
              onChange={handleZipCodeChange} // Use the new handler
              error={Boolean(formik.errors.zipcode)}
              helperText={formik.errors.zipcode}
              InputProps={{
                style: {
                  borderRadius: '8px',
                  borderColor: 'rgba(227, 227, 227, 1)',
                },
              }}
            />
          </Grid>
        </Grid>
        <div style={{ height: "20px" }}></div>
        {/* 
        <div style={{ height: "20px" }}></div> */}
        <Grid container spacing={2}>

          {/* <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  value={formik.values.state}
                  onChange={(_, value) =>
                    formik.setFieldValue("state", value?.value)
                  }
                  id="combo-box-state"
                  options={[
                    { label: "Alberta", value: "Alberta" },
                    { label: "British Columbia", value: "British Columbia" },
                    { label: "Manitoba", value: "Manitoba" },
                    { label: "New Brunswick", value: "New Brunswick" },
                    { label: "Newfoundland and Labrador", value: "Newfoundland and Labrador" },
                    { label: "Nova Scotia", value: "Nova Scotia" },
                    { label: "Ontario", value: "Ontario" },
                    { label: "Prince Edward Island", value: "Prince Edward Island" },
                    { label: "Quebec", value: "Quebec" },
                    { label: "Saskatchewan", value: "Saskatchewan" },
                    { label: "Northwest Territories", value: "Northwest Territories" },
                    { label: "Nunavut", value: "Nunavut" },
                    { label: "Yukon", value: "Yukon" },
                  ]}
                  renderInput={(params) => (

                    <>

                      <Typography component="label" htmlFor="last_nameaddress" sx={labelStyles}>
                        State
                      </Typography>
                      <TextField
                        {...params}
                        // label="State"
                        helperText={formik.errors.state}
                        error={Boolean(formik.errors.state)}
                        placeholder="ex. Alberta"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            borderRadius: "8px",
                            borderColor: "rgba(227, 227, 227, 1)",
                          },
                        }}
                      />

                    </>
                  )}
                />
              </FormControl>
            </Grid> */}

          {/* <Grid item xs={6}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                value={formik.values.state || state}
                onChange={(_, value) => formik.setFieldValue('state', value?.value)}
                id="combo-box-state"
                options={[
                  { label: "Alberta", value: "Alberta" },
                  { label: "British Columbia", value: "British Columbia" },
                  { label: "Manitoba", value: "Manitoba" },
                  { label: "New Brunswick", value: "New Brunswick" },
                  { label: "Newfoundland and Labrador", value: "Newfoundland and Labrador" },
                  { label: "Nova Scotia", value: "Nova Scotia" },
                  { label: "Ontario", value: "Ontario" },
                  { label: "Prince Edward Island", value: "Prince Edward Island" },
                  { label: "Quebec", value: "Quebec" },
                  { label: "Saskatchewan", value: "Saskatchewan" },
                  { label: "Northwest Territories", value: "Northwest Territories" },
                  { label: "Nunavut", value: "Nunavut" },
                  { label: "Yukon", value: "Yukon" },
                ]}
                renderInput={(params) => (
                  <>
                    <Typography component="label" htmlFor="last_nameaddress" sx={labelStyles}>
                      State
                    </Typography>
                    <TextField
                      {...params}
                      helperText={formik.errors.state}
                      error={Boolean(formik.errors.state)}
                      placeholder="ex. Alberta"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          borderRadius: '8px',
                          borderColor: 'rgba(227, 227, 227, 1)',
                        },
                      }}
                    />
                  </>
                )}
              />
            </FormControl>
          </Grid> */}

          {/* 
          <Grid item xs={6}>
            <Typography component="label" htmlFor="city" sx={labelStyles}>
              State
            </Typography>
            <TextField
              fullWidth
              placeholder="ex. Calgary"
               id="combo-box-state"
              name="city"
              variant="outlined"
              value={formik.values.state || state}
                onChange={(_, value) => formik.setFieldValue('state', value?.value)}
              error={Boolean(formik.errors.state)}
              helperText={formik.errors.state}
              InputProps={{
                style: {
                  borderRadius: '8px',
                  borderColor: 'rgba(227, 227, 227, 1)',
                },
              }}
            />
          </Grid> */}


          <Grid item xs={6}>
            <Typography component="label" htmlFor="state" sx={labelStyles}>
              State
            </Typography>
            <TextField
              fullWidth
              placeholder="ex. Ontario"
              id="state"
              name="state"
              variant="outlined"
              value={formik.values.state || state} // Use the city from API or the formik value
              onChange={(e) => {
                const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Remove digits and special characters
                formik.setFieldValue('state', value);
              }}
              error={Boolean(formik.errors.state)}
              helperText={formik.errors.state}
              InputProps={{
                readOnly: true, 
                style: {
                  borderRadius: '8px',
                  borderColor: 'rgba(227, 227, 227, 1)',
                },
              }}
            />
          </Grid>


          <Grid item xs={6}>
            <Typography component="label" htmlFor="city" sx={labelStyles}>
              City
            </Typography>
            <TextField
              fullWidth
              placeholder="ex. Calgary"
              id="city"
              name="city"
              variant="outlined"
              value={formik.values.city || city} // Use the city from API or the formik value
              onChange={(e) => {
                const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Remove digits and special characters
                formik.setFieldValue('city', value);
              }}
              error={Boolean(formik.errors.city)}
              helperText={formik.errors.city}
              InputProps={{
                readOnly: true, 
                style: {
                  borderRadius: '8px',
                  borderColor: 'rgba(227, 227, 227, 1)',
                },
              }}
            />
          </Grid>

          {/* <Grid item xs={6}>

              <Typography component="label" htmlFor="zipcode" sx={labelStyles}>
                Zip Code
              </Typography>

              <TextField
                fullWidth
                id="zipcode"
                // label="Zip Code"
                placeholder="ex. T5A 0A1"
                name="zipcode"
                variant="outlined"
                value={formik.values.zipcode}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.zipcode)}
                helperText={formik.errors.zipcode}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "rgba(227, 227, 227, 1)",
                  },
                }}
              />
            </Grid> */}
        </Grid>
        <div style={{ height: "20px" }}></div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl sx={{

              width: "49%",
              "@media (max-width: 850px)": {
                width: "100%",
              },
            }}>
              <Typography component="label" htmlFor="phone-number" sx={labelStyles}>
                Phone Number
              </Typography>
              <TextField
                fullWidth
                id="phone-number"
                name="mobile_number"
                variant="outlined"
                placeholder="ex. 9876543456"
                value={formik.values.mobile_number}
                onChange={(e) => {
                  // Ensure only digits are entered and limit to 10 digits
                  const value = e.target.value.replace(/\D/g, "").slice(0, 10); // Remove non-digits and limit to 10 digits
                  formik.setFieldValue("mobile_number", value);
                }}
                error={Boolean(formik.errors.mobile_number) || Boolean(formik.errors.mobile_number_country_code)}
                helperText={formik.errors.mobile_number || formik.errors.mobile_number_country_code}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* Country Code Dropdown */}
                      <Autocomplete
                        sx={{
                          '& .MuiAutocomplete-clearIndicator': {
                            display: 'none !important',
                          },
                          '& .MuiInputBase-input': {
                            minWidth: '60px !important',
                          },
                          '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                            minWidth: '66px !important',
                          },
                        }}
                        disablePortal
                        value={CountryCodes.find(
                          (code) => code.dial_code === formik.values.mobile_number_country_code
                        ) || null} // Ensure null if no match is found
                        onChange={(_, value) => {
                          formik.setFieldValue("mobile_number_country_code", value?.dial_code || ""); // Handle null
                        }}
                        options={CountryCodes}
                        getOptionLabel={(option) => `${option.flag} ${option.dial_code}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="ex. +1"
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              style: { width: 80, fontSize: '0.875rem' },
                            }}
                          />
                        )}
                      />
                      {/* Divider */}
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ mx: 1, height: 28, alignSelf: "center", backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                      />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "8px",
                    borderColor: "rgba(227, 227, 227, 1)",
                  },
                }}
                inputProps={{
                  maxLength: 10, // Limit to 10 digits
                  pattern: "[0-9]*", // Allow only digits
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ height: "20px" }}></div>

        <ContinueButton type="submit" disabled={false} >
          Save
        </ContinueButton>
        <ContinueButton
          type="button"
          onClick={() => {
            formik.resetForm();
            resetZipCode();

          }}
        >
          Add New Address
        </ContinueButton>
        <Divider />

        <HeadingSaved>Select Saved Address</HeadingSaved>

        {userAddresses.map((item) => {
          return (
            <SingleAddressitem
              isSelected={formik?.values?.selectedAddress === item?.id}
              func={() => {
                const { id, ...rest } = item;
                formik.setValues({
                  ...formik.values,
                  ...rest,
                  selectedAddress: item.id,
                });
              }}
              key={item?.id}
              addStr={`${item?.address || ""} ${item?.landmark || ""} 
               ${item?.city || ""} ${item?.state || ""}  ${item?.mobile_number || ""
                } `}
            />
          );
        })}
      </WidthAdjuster>
    </form>
  );
}

export default AddressForm;
