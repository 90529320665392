import React from "react";
import styled from "@emotion/styled";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useDispatch } from "react-redux";
import { selectProductToFetchDetails } from "../../store/reducer/reducer";
import { useNavigate } from "react-router-dom";
import { formatDollars } from "../../helper/generalFunctions";
import NoImage from "../../assets/images/no-image.jpeg";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { get, post } from "../../api_helper/api_helper";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { toast } from "react-toastify";
import { useRef } from "react";
const ParentProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s;
  cursor: pointer;
  height: auto;
  margin: 10px;
  max-width: 100%;
position:relative;
  @media (min-width: 600px) {
    max-width: 500px;
    margin: 20px 18px;
  }

    @media (min-width: 401px) and (max-width: 432px) {
  height: 324px;
}

  @media (min-width: 380px) and (max-width: 399px) {
  height: 315px;
}

    @media (max-width: 380px) {
  height: 305px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%; // Creates a 1:1 aspect ratio
  position: relative;
  overflow: hidden;
 box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const ProductName = styled.div`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  margin: 17px 10px 0;
  text-align: left;
  height: 38px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PriceBox = styled.div`
  font-family: "Inter";
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;

  @media (max-width: 922px) {
    font-size: 0.88rem;
  }
`;

const SellingPrice = styled.span`
  font-family: "Inter";
  font-weight: bold;
  color: black;
`;

const MRP = styled.span`
  font-family: "Inter";
  text-decoration: line-through;
  color: #999;
  margin-left: 5px;
`;

const Discount = styled.span`
  font-family: "Inter";
  color: #999;
  margin-left: 10px;
  font-weight: 400;
  margin-bottom:19px;
`;

const AddToCartBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00B4D8;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: calc(100% - 20px);

  &:hover {
    background-color: #008CBA;
  }
`;
const PositionAbsolute = styled.div`
position: absolute;
right:5px;
top:5px;
z-index: 1000;
color:red;
`;



export default function SingleNewProduct({ product, displayAddToCartBtn, getUserWishlistItems, wishlistItems, setWishlistItems }) {
  // console.log("Hell",product)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.general.user);
  const renderCount = useRef(0);
  // console.log("Hello Wishlist Items", wishlistItems)
  // console.log("Wishlist Products", wishlistItems)
  const finalProduct = product;
  // console.log("Going", finalProduct?.product_variants[0]?.discountedPrice)
  // console.log("Check From SIngle Product",finalProduct)
  // console.log("dgrt".wishlistItems?.find(el => el?.product?.id === product?.id));
  // let Image_Urls=finalProduct.product_variants[0].image_urls[0];

  // console.log("Recently Viewed Products", finalProduct)
  // const { category, sub_category, brand_name, name } = product?.product;

  useEffect(() => {
    renderCount.current += 1;
    // console.log(`SingleProduct (${product.id}) render #${renderCount.current}. wishlistItems:`, wishlistItems);
  });

  const calculateDiscountPercentage = () => {
    if (finalProduct?.discountedPrice && finalProduct?.MRP) {
      const discountedPrice = parseFloat(finalProduct.discountedPrice);
      const mrp = parseFloat(finalProduct.MRP);
      const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
      return Math.round(discountPercentage);
    }
    return 0;
  };


  const calculateDiscountPercentageVariance = () => {
    if (finalProduct?.product_variants[0]?.discountedPrice && finalProduct?.product_variants[0]?.MRP) {
      const discountedPrice = parseFloat(finalProduct?.product_variants[0]?.discountedPrice);
      const mrp = parseFloat(finalProduct?.product_variants[0]?.MRP);
      const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
      return Math.round(discountPercentage);
    }
    return 0;
  };

  console.log("Selected Recently Viewed", finalProduct)

  const handleProductClick = () => {


    window.scrollTo(0, 0) // Scroll to the top when the component is mounted

    dispatch(selectProductToFetchDetails(finalProduct));
    {

      finalProduct?.contain_variants ?

        navigate(`/product-details/${finalProduct?.category?.name
          }/${finalProduct?.sub_category?.name}/${finalProduct?.name}`)

        :

        navigate(`/product-details/${finalProduct?.category?.name
          }/${finalProduct?.sub_category?.name}/${finalProduct?.name}`)


    }


    // navigate(`/product-details/${finalProduct?.categoryName
    // }/${finalProduct?.subCategoryName}/${finalProduct?.brand_name}/${finalProduct?.name}`);


    const existingProducts = JSON.parse(localStorage.getItem('RecentlyViewed')) || [];

    // Check if the product is already in the array
    const isProductAlreadyViewed = existingProducts.some(product => product?.id === finalProduct?.id);

    // Add the product only if it doesn't already exist
    if (!isProductAlreadyViewed) {
      existingProducts.push(finalProduct);

      // Store the updated array back in localStorage
      localStorage.setItem('RecentlyViewed', JSON.stringify(existingProducts));
    }
  };

  // const isInWishlist = Array.isArray(wishlistItems) && wishlistItems.some(item => item.product.id === product.id);
  // const isProductInWishlist = wishlistItems?.find(el => el?.product_id === product?.product_id);
  // console.log("Is product in wishlist:", isProductInWishlist);
  const selectedVariant = product?.id;
  const notMatched = selectedVariant !== product?.id;

  const bool = wishlistItems?.find(el => {
    // Base product check
    if (!notMatched && !el?.product_variant_id) {
      return el?.product?.id === product?.id;
    }

    // Variant check
    if (notMatched) {
      return (
        el?.product?.id === product?.id &&
        el?.product_variant_id === selectedVariant
      );
    }

    // Default (if no variant specified, check base product only)
    return el?.product?.id === product?.id && !el?.product_variant_id;
  });
  return (
    <ParentProductWrapper onClick={handleProductClick}>
      {/* {user && (
        <HeartIcon
          isInWishlist={isInWishlist}
          product={product}
          getUserWishlistItems={getUserWishlistItems}
          isWishlistLoading={isWishlistLoading}
        />
      )} */}

      {/* <PositionAbsolute>
        <FavoriteBorderOutlinedIcon style={{ color: "black" }} />
      </PositionAbsolute> */}

      <HeartIcon bool={bool} item={product} getUserWishlistItems={getUserWishlistItems} user={user} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} />
      {/* <HeartIcon bool={wishlistItems?.find(el => el?.product?.id === product?.id)} item={product} getUserWishlistItems={getUserWishlistItems} user={user}  wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} /> */}

      <ImageWrapper>
        <img
          alt="product"
          src={
            finalProduct?.image_urls?.length
              ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${finalProduct?.image_urls[0]}`
              : NoImage
          }
        />
      </ImageWrapper>
      <ProductName>{finalProduct?.name || finalProduct?.productName}</ProductName>
      <PriceBox>
        {finalProduct?.discountedPrice || finalProduct?.product_variants[0]?.discountedPrice ? (
          <>
            <SellingPrice>
              {formatDollars(finalProduct?.discountedPrice)}
            </SellingPrice>

            <MRP>{formatDollars(finalProduct?.MRP)}</MRP>
          </>
        ) : (
          <SellingPrice>{formatDollars(finalProduct?.MRP)
          }</SellingPrice>
        )}
      </PriceBox>


      <Discount>{calculateDiscountPercentage()}% off </Discount>
      {displayAddToCartBtn && (
        <AddToCartBtn>
          <ShoppingCartOutlinedIcon style={{ color: "white" }} />
          <span style={{ marginLeft: "5px" }}>Add to cart</span>
        </AddToCartBtn>
      )}
    </ParentProductWrapper>
  );
}




function HeartIcon({ bool, item, getUserWishlistItems, user, setWishlistItems, wishlistItems }) {


  let brand_name;
  // console.log("Section Item", item)

  // {console.log(item)}
  const [loading, setLoading] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(bool); // Track if the item is in the wishlist

  const calculateDiscountPercentage = (item) => {
    if (item.discountedPrice && item.MRP) {
      return Math.round(((item.MRP - item.discountedPrice) / item.MRP) * 100);
    }
    return 0; // Return 0 if no discount is available
  };

  // const selectedVariant = item?.product_variants?.[0]?.id;
  const selectedVariant = item?.id;
  const notMatched = selectedVariant !== item?.id;

  // useEffect(() => {
  //   if (user) {
  //     if (item?.contain_variants) {
  //       setIsInWishlist(
  //         wishlistItems?.some(
  //           (wishlistItem) =>
  //             wishlistItem?.product_id === item?.product_id &&
  //             wishlistItem?.product_variant_id === selectedVariant
  //         )
  //       );
  //     } else {
  //       setIsInWishlist(bool);
  //     }
  //   }
  // }, [user, wishlistItems, item?.id, selectedVariant, bool]);

  useEffect(() => {
    if (user) {
      if (item?.contain_variants && notMatched) {
        setIsInWishlist(
          wishlistItems?.some(
            (wishlistItem) =>
              wishlistItem?.product_id === item?.id &&
              wishlistItem?.product_variant_id === selectedVariant
          )
        );
      } else {
        // For base products (no variants involved or matched)
        setIsInWishlist(
          wishlistItems?.some(
            (wishlistItem) =>
              wishlistItem?.product_id === item?.id && !wishlistItem?.product_variant_id
          )
        );
      }
    }
  }, [user, wishlistItems, item?.id, selectedVariant, bool]);


  // Sync wishlist state with localStorage or server when component mounts
  // useEffect(() => {
  //   if (user) {


  //     // For logged-in users, you can call an API to check the wishlist status
  //     // Example: Check if item is in the server-side wishlist
  //     // You may replace this with an API call if needed.
  //     setIsInWishlist(bool);
  //   }
  // }, [user, bool, item?.id]);

  // useEffect(() => {
  //   if (!user) {
  //     const isItemInWishlist = wishlistItems?.some(wishlistItem => wishlistItem?.id === item?.id);
  //     setIsInWishlist(isItemInWishlist);
  //   }
  // }, [wishlistItems, item?.id]);


  useEffect(() => {
    if (!user) {
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      const isItemInWishlist = storedWishlist.some((wishlistItem) => {
        if (item?.contain_variants) {
          return (
            wishlistItem.id === item?.id &&
            wishlistItem.product_variant_id === selectedVariant
          );
        }
        return wishlistItem.id === item?.id;
      });
      setIsInWishlist(isItemInWishlist);
    }
  }, [user, item?.id, selectedVariant]);

  // Add item to wishlist (for guest users, store in localStorage)
  const addToWishlist = async () => {
    try {
      setLoading(true);

      if (!user) {
        // For guest users, store product details in localStorage
        const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        const newItem = {
          id: item?.id,
          name: item?.name,
          category: item?.categoryName,
          childcategory: item?.childCategoryName,
          subcategory: item?.subCategoryName,
          brand_name: item?.brand_name,
          price: item?.MRP,
          image: item?.image_urls[0], // Ensure image URL is stored
          discountedPrice: item?.discountedPrice,
          MRP: item?.MRP,
          discountPercentage: calculateDiscountPercentage(item),
          weight: item?.weight,
          width: item?.width,
          length: item?.length,
          height: item?.height,
          contain_variants: notMatched ? item?.contain_variants : false,
          product_variants: item?.product_variants,
          product_variant_id: selectedVariant,
          inStock: item?.inStock,
        };

        storedWishlist.push(newItem);
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

        setIsInWishlist(true); // Set state to true after adding to the wishlist
        toast.success("Added to wishlist (guest user)");

        setLoading(false);
        getUserWishlistItems();
        return;
      }

      // console.log("Recently products :- ", item)

      // For logged-in users, use API
      await get(
        `/api/wishlist/add/${item?.id}${item?.contain_variants && notMatched ? `/${selectedVariant}` : ''}`
      );
      setIsInWishlist(true);
      toast.success("Added to wishlist");
      getUserWishlistItems();
    } catch (e) {
      console.error("Error adding to wishlist:", e);
      toast.error(e?.response?.data?.error || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  // await get(`/api/wishlist/add/${item?.id}${item?.contain_variants ? `/${item?.product_variants[0].id}` : ''}`);

  // { item?.contain_variants ? await get(`/api/wishlist/add/${item?.product_id}${item?.contain_variants ? `` : ''}`) : await get(`/api/wishlist/add/${item?.id}${item?.contain_variants ? `` : ''}`) }


  // await get(`/api/wishlist/add/${item?.product_id}${item?.contain_variants ? `` : ''}`);


  // Remove item from wishlist (for guest users, remove from localStorage)
  // const removeFromWishlist = async () => {

  //   console.log("Removing.....", bool?.id)
  //   try {
  //     setLoading(true);

  //     if (!user) {
  //       // For guest users, remove from localStorage
  //       let storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  //       storedWishlist = storedWishlist.filter((wishlistItem) => wishlistItem.id !== item?.id);
  //       localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

  //       setIsInWishlist(false); // Set state to false after removing from wishlist
  //       toast.success("Removed from wishlist (guest user)");

  //       setLoading(false);
  //       getUserWishlistItems();
  //       return;
  //     }
  //     // let wishlistItemId = item?.id;
  //     // For logged-in users, use API
  //     await get(`/api/wishlist/remove/${bool?.id}`);
  //     console.log("Hello ID", item?.id)
  //     setIsInWishlist(false); // Set state to false after removing from wishlist
  //     toast.success("Removed from wishlist");
  //     setLoading(false);
  //     getUserWishlistItems(); // Refresh wishlist from the server
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(e?.response?.data?.error || "Something went wrong");
  //   }
  // };

  const removeFromWishlist = async () => {

    console.log("Removing.....", bool?.id)
    try {
      setLoading(true);

      // if (!user) {
      //   // For guest users, remove from localStorage
      //   let storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      //   storedWishlist = storedWishlist.filter((wishlistItem) => wishlistItem.id !== item?.id);
      //   localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

      //   setIsInWishlist(false); // Set state to false after removing from wishlist
      //   toast.success("Removed from wishlist (guest user)");

      //   setLoading(false);
      //   getUserWishlistItems();
      //   return;
      // }

      if (!user) {
        // Guest user logic
        let storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        storedWishlist = storedWishlist.filter((wishlistItem) => {
          if (item?.contain_variants) {
            return !(
              wishlistItem.id === item?.id &&
              wishlistItem.product_variant_id === selectedVariant
            );
          }
          return wishlistItem.id !== item?.id;
        });
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
        setIsInWishlist(false);
        toast.success("Removed from wishlist (guest user)");
        setLoading(false);
        return;
      }

      const variantToRemove = item?.contain_variants && notMatched
        ? wishlistItems.find(
          (wishlistItem) =>
            wishlistItem.product_id === item?.id &&
            wishlistItem.product_variant_id === selectedVariant
        )
        : null;



      if (item?.contain_variants && !variantToRemove?.id && notMatched) {
        console.error("Variant not found in wishlist for removal");
        toast.error("Error removing item. Please refresh and try again.");
        setLoading(false);
        return;
      }

      // API call to remove the item
      await get(
        item?.contain_variants && notMatched
          ? `/api/wishlist/remove/${variantToRemove?.id}`
          : `/api/wishlist/remove/${bool?.id}`
      );

      setIsInWishlist(false);
      getUserWishlistItems();
      toast.success("Removed from wishlist");
    } catch (e) {
      console.error("Error removing from wishlist:", e);
      toast.error(e?.response?.data?.error || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  // Decide whether to add or remove the item from wishlist
  function callFunctionAccordingly(event) {
    event.stopPropagation();
    if (isInWishlist) {
      removeFromWishlist();
    } else {
      addToWishlist();
    }
  }

  return isInWishlist ? (
    <PositionAbsolute onClick={callFunctionAccordingly}>
      <FavoriteIcon style={{ color: "rgb(218, 72, 72)" }} /> {/* Red when added to wishlist */}
    </PositionAbsolute>
  ) : (
    <PositionAbsolute onClick={callFunctionAccordingly}>
      <FavoriteBorderOutlinedIcon style={{ color: "black" }} /> {/* Black when not in wishlist */}
    </PositionAbsolute>
  );
}


// function HeartIcon({ bool, item, user }) {
//   const [loading, setLoading] = useState(false);
//   const [isInWishlist, setIsInWishlist] = useState(bool); // Track if the item is in the wishlist

//   const calculateDiscountPercentage = (item) => {
//     if (item.discountedPrice && item.MRP) {
//       return Math.round(((item.MRP - item.discountedPrice) / item.MRP) * 100);
//     }
//     return 0; // Return 0 if no discount is available
//   };

//   // Sync wishlist state with localStorage or sessionStorage when component mounts
//   useEffect(() => {
//     const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
//     const isItemInWishlist = storedWishlist.some(wishlistItem => wishlistItem.id === item?.product_id);
//     setIsInWishlist(isItemInWishlist);
//   }, [item?.product_id]);

//   // Add item to wishlist (store in localStorage)
//   const addToWishlist = () => {
//     try {
//       setLoading(true);

//       const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
//       const newItem = {
//         id: item?.product_id,
//         name: item?.product?.name,
//         price: item?.product?.MRP,
//         image: item?.product?.image_urls[0], // Ensure image URL is stored
//         discountedPrice: item?.product?.discountedPrice,
//         MRP: item?.product?.MRP,
//         discountPercentage: calculateDiscountPercentage(item.product),
//         weight: item?.product?.weight,
//         width: item?.product?.width,
//         length: item?.product?.length,
//         height: item?.product?.height,
//       };

//       // Add the new item to the wishlist and update localStorage
//       storedWishlist.push(newItem);
//       localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

//       setIsInWishlist(true); // Set state to true after adding to the wishlist
//       toast.success("Added to wishlist");

//       setLoading(false);
//     } catch (e) {
//       setLoading(false);
//       toast.error("Something went wrong");
//     }
//   };

//   // Remove item from wishlist (remove from localStorage)
//   const removeFromWishlist = () => {
//     try {
//       setLoading(true);

//       let storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
//       storedWishlist = storedWishlist.filter((wishlistItem) => wishlistItem.id !== item?.product_id);
//       localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

//       setIsInWishlist(false); // Set state to false after removing from wishlist
//       toast.success("Removed from wishlist");

//       setLoading(false);
//     } catch (e) {
//       setLoading(false);
//       toast.error("Something went wrong");
//     }
//   };

//   // Decide whether to add or remove the item from wishlist
//   function callFunctionAccordingly(event) {
//     event.stopPropagation();
//     if (isInWishlist) {
//       removeFromWishlist();
//     } else {
//       addToWishlist();
//     }
//   }

//   return isInWishlist ? (
//     <PositionAbsolute onClick={callFunctionAccordingly}>
//       <FavoriteIcon style={{ color: "rgb(218, 72, 72)" }} /> {/* Red when added to wishlist */}
//     </PositionAbsolute>
//   ) : (
//     <PositionAbsolute onClick={callFunctionAccordingly}>
//       <FavoriteBorderOutlinedIcon style={{ color: "black" }} /> {/* Black when not in wishlist */}
//     </PositionAbsolute>
//   );
// }



// function HeartIcon({ isInWishlist, product, getUserWishlistItems, isWishlistLoading }) {
//   const handleWishlistAction = async (event) => {
//     event.stopPropagation();
//     if (isWishlistLoading) return;

//     try {
//       if (isInWishlist) {
//         await get(`/api/wishlist/remove/${product.id}`);
//         toast.success("Removed from wishlist");
//       } else {
//         await get(`/api/wishlist/add/${product.id}${product?.contain_variants ? `/${product?.product_variants[0].id}` : ''}`);
//         toast.success("Added to wishlist");
//       }
//       getUserWishlistItems();
//     } catch (error) {
//       console.error("Error updating wishlist:", error);
//       toast.error("Failed to update wishlist");
//     }
//   };

//   return (
//     <PositionAbsolute onClick={handleWishlistAction}>
//       {isWishlistLoading ? (
//         <span>Loading...</span>
//       ) : isInWishlist ? (
//         <FavoriteIcon style={{ color: "black" }} />
//       ) : (
//         <FavoriteBorderOutlinedIcon style={{ color: "black" }} />
//       )}
//     </PositionAbsolute>
//   );
// }