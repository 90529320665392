import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ProuductShowCase from "../../components/ProductDetails/ProductShowCase";
import ProductAddtoCartAndDetails from "../../components/ProductDetails/ProductAddtoCartAndDetails";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { post,get } from "../../api_helper/api_helper";
import { setUserDetails } from "../../store/reducer/reducer";
import ProductDescription from "../../components/ProductDetails/ProductDescription";
import AddToCartBuyNow from "../../components/ProductDetails/AddToCartBuyNow";
import AccordianBox from "../../components/ProductDetails/AccordianBox";
import ProductDetailsProductSlider from "../../components/ProductDetails/PopularProductSlider";
import RecentlyProduct from "../../components/LandingPage/RecentlyProduct";
import { useLocation } from 'react-router-dom';
const WidthAdjuster = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
  max-width:1525px;
  @media (max-width: 600px) {
    width: 97%;
  }

    @media (max-width: 1100px) {
    width: 95%;
  }
`;

const DisplayFlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 600px) {
    flex-direction: column;
  }

    @media (max-width: 1100px) {
    flex-direction: column;
  }
 
`;


const RecentlyPro = styled.div`
  font-family: "Inter";
  font-weight: 600;
  font-size: 24px;
  color:#3E4D50;
  line-height: 29.05px;
  @media (max-width: 600px) {
    font-size: 19px;
  line-height: 24.05px;
  }
`;


function ProductDetails() {
  const [qty, setQty] = useState(1);
  const [typeOfShipment, setTypeOfShipment] = useState(null);
  const [product, setProduct] = useState(null);
  const location = useLocation();
  // const { productState } = location.state || {};
  // console.log("MainState",productState)
  // console.log("Details Of Products::---", product?.id)
  const [popularProducts, setPopularProducts] = useState([]);
  const [shippingDetails, setShippingDetails] = useState({});
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [baseItem, setBaseItem]=useState(null)
  const [variantColor, setVariantColor] = useState(null);
  const dispatch = useDispatch();
  // const finalProduct = product?.contain_variants
  // ? { ...product, ...product?.product_variants[0] }
  // : product;
  const notMatched = selectedVariant !== product?.id;

  const { category, subcategory,  productname } = useParams();

// console.log("Category:", category);
// console.log("Subcategory:", subcategory);
// console.log("Brand:", brand);
// console.log("Product name:", productname);
console.log("Product Details For Variant", product)
  const productSelected = useSelector((state) => state.general.selectProductToFetchDetails);
  console.log("Redux productSelected:", productSelected); // Add this line
// console.log("Hello Product", productSelected)
  const user = useSelector((state) => state.general.user);
  const [wishlistItems, setWishlistItems] = useState([]);
  let MainVariant=productSelected?.productVariants;
  // console.log("Selected Products :", productSelected)
  // console.log("Products :",product)
  const [currentVariant, setCurrentVariant] = useState(MainVariant);
  async function getUserWishlistItems() {
    if (user) {
      try {
        const items = await post("/api/wishlist", {});
        console.log("Fetched wishlist items (logged-in user):", items?.wishlist?.wishlist_items);
        setWishlistItems(items?.wishlist?.wishlist_items || []);
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    } else {
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      console.log("Fetched wishlist items (guest user):", storedWishlist);
      setWishlistItems(storedWishlist);
    }
  }
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { userProfile } = await get("/api/auth/profile-info");
        dispatch(setUserDetails(userProfile || {}));
      } catch (e) {
        console.error("Error fetching user details:", e);
      }
    };

    fetchUserDetails();
  }, [dispatch]);	
  useEffect(() => {
    getUserWishlistItems();
  }, []); 
  
  useEffect(() => {
    console.log("ProductSlider: wishlistItems updated:", wishlistItems);
  }, [wishlistItems]);
// console.log("Selected Prod",productname)


  // const fetchProductDetails = async () => {
  //   try {
  //     if (category && subcategory && brand && productname) {
  //       const details = await post("/api/get-product-by-slug", {
  //         category,
  //         subcategory,
  //         brand,
  //         name: productname, // Assuming "name" is what the API expects for the product name
  //       });
  //       console.log("API response details:", details);
  //       if (details) setProduct(details);
  //       else setProduct(null);
  //     } else if (productSelected) {
  //       const details = await post(`/api/products/single/${productSelected.id}`);
  //       setProduct(details?.product);
  //     } else {
  //       console.warn("No product found in URL or Redux state.");
  //       setProduct(null);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching product details:", error);
  //     setProduct(null);
  //   }
  // };
  
  // useEffect(() => {
  //   fetchProductDetails();
  // }, [category, subcategory, brand, productname, productSelected]); 

  const [currentProductIdentifier, setCurrentProductIdentifier] = useState('');
  const RecentlyViewed = JSON.parse(localStorage.getItem('RecentlyViewed')) || [];

  const fetchProductDetails = async () => {
    try {
      // Create a unique identifier for the product from URL parameters
      const newIdentifier = `${category}-${subcategory}-${productname}`;

      // Only fetch if the identifier has changed
      if (newIdentifier !== currentProductIdentifier) {
        setCurrentProductIdentifier(newIdentifier); // Update current product identifier

        if (category && subcategory &&  productname) {
          const details = await post("/api/get-product-by-slug", {
            category,
            subcategory,
          
            name: productname,
          });
          console.log("API response details:", details);
          if (details) setProduct(details);
          else setProduct(null);
        } else if (productSelected?.productDetails) {
          const details = await post(`/api/products/single/${productSelected?.productDetails?.id}`);
          setProduct(details?.product);
        } else {
          console.warn("No product found in URL or Redux state.");
          setProduct(null);
        }
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
      setProduct(null);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [category, subcategory, productname, productSelected]); // Ensure to include relevant dependencies

  async function getLandingPageDetails() {
    try {
      const data = await get("/api/ecom-dashboard/web");
      setPopularProducts(data?.propularProducts || []);
    } catch (e) {
    }
  }

  async function getShippingDetails() {
    try {
      const shippingDetails = await post("/api/ecom-calculate-shipping", {
        productId: product?.id,
        ...(notMatched && { productVariantId: selectedVariant }),
      });

      setShippingDetails(shippingDetails);

    } catch (e) {
    }
  }
  console.log("Details of Shipping",  product?.id)

  // useEffect(() => {


  //   getLandingPageDetails();

  //   return () => {
  //     setSelectedVariant();
  //   };
  // }, []);

  useEffect(() => {

    if (product?.contain_variants && product?.product_variants?.length > 0) {
      if (MainVariant) {
        // Set MainVariant if available
        setSelectedVariant(MainVariant?.id);
        setVariantColor(MainVariant?.color);
        setCurrentVariant(MainVariant);
      } else {
        // Otherwise, set the first variant as the default
        setSelectedVariant(product?.id);
        setVariantColor(product?.color);
        setCurrentVariant(product);
      }
    }




    getLandingPageDetails();

    return () => {
      setSelectedVariant(null);  // Reset selected variant on unmount
      setVariantColor(null);
    };
  }, [product]);

  useEffect(() => {
    getShippingDetails();
  }, [product, selectedVariant]);
  const selectedVariantDetails = productSelected?.product_variants?.find(
    (variant) => variant.id === selectedVariant
  );
  
  const storedProduct = JSON.parse(localStorage.getItem('Recently Viewed'));
  // console.log("Recently Viewed", storedProduct)
  return product ? (
    <WidthAdjuster>
      <DisplayFlexSpaceBetween>
        <ProuductShowCase
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
          product={product}
          getUserWishlistItems={getUserWishlistItems} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems}
          // productSelected={productState}
          notMatched={notMatched}
        />


        <ProductAddtoCartAndDetails
          setQty={setQty}
          qty={qty}
          typeOfShipment={typeOfShipment}
          setTypeOfShipment={setTypeOfShipment}
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
          currentVariant={currentVariant}
          setCurrentVariant= {setCurrentVariant}
          product={product}
          shippingDetails={shippingDetails}
          setVariantColor={setVariantColor}
          variantColor={variantColor}
          // productSelected={productState}
         
        />
              
      </DisplayFlexSpaceBetween>
      <DisplayFlexSpaceBetween>
        <ProductDescription product={product} />
        <AddToCartBuyNow qty={qty} typeOfShipment={typeOfShipment} product={product} selectedVariant={selectedVariant}  notMatched={notMatched}  shippingDetails={shippingDetails}    setSelectedVariant={setSelectedVariant} />
      </DisplayFlexSpaceBetween>
      <DisplayFlexSpaceBetween>
        <AccordianBox product={product} />
      </DisplayFlexSpaceBetween>  

      <ProductDetailsProductSlider products={RecentlyViewed} getUserWishlistItems={getUserWishlistItems} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems}  />
      {/* <ProductDetailsProductSlider products={RecentlyViewed} getUserWishlistItems={getUserWishlistItems} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems}  /> */}
    </WidthAdjuster>
  ) : (
    <div>Loading...</div>
  );
}

export default ProductDetails;
