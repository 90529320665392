import React from "react";
import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";
import IndividualCategory from "./IndividualCategory";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import { selectedCategoryFromLandingPage, selectedSubCategory } from "../../store/reducer/reducer";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import PopularProductSlider from "../LandingPage/PopularProductSlider";
import SingleProduct from "../LandingPage/SingleProduct";
import { useState } from "react";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { get, post } from "../../api_helper/api_helper";
import { useRef } from "react";
import { useMediaQuery } from "@mui/material";
import { Margin } from "@mui/icons-material";
import { useLocation } from 'react-router-dom';
import empty_category from "../../assets/images/categories_empty.png"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import RecentlyProduct from "../LandingPage/RecentlyProduct";
import { useSelector } from "react-redux";

import { setUserDetails } from "../../store/reducer/reducer";
const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  color:#3E4D50;
  margin: 20px auto;
  margin:70px 0;
    @media (max-width: 600px) {
    font-size: 19px;
  line-height: 24.05px;
  }

     @media (max-width: 1024px) {
 margin:28px 0;
  }
`;

const Heading2 = styled.div`
  font-family: "Inter";
  font-weight: 500;
  font-size: 24px;
  line-height: 29.05px;
  @media (max-width: 600px) {
    font-size: 19px;
  line-height: 24.05px;
  }
`;

const ViewEnd = styled.div`
  display: none;
  margin: 20px auto;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

    @media (max-width: 1024px) {
display:flex;
  }


`;

const GoBack = styled.span`
  font-weight: 500;
  font-size: 20px;
  font-family: "Inter";
  cursor: pointer;
`;

const ArrowBackIconStyled = styled(ArrowBackIcon)`
  cursor: pointer;

  font-size: 18px; /* Larger size for visibility */
  padding: 6px; /* Padding for better clickability */
  border: 2px solid #25626f; /* Add border for modern design */
  border-radius: 50%; /* Make it circular */
  transition: all 0.3s ease;
     background-color: #25626f; /* Fill background on hover */
    color: #fff; /* Change arrow color to white */
  &:hover {
      background-color: #25626f; /* Fill background on hover */
    color: #fff; /* Change arrow color to white */
    transform: scale(1.1); /* Subtle zoom effect */
  }

  &:active {
    transform: scale(1); /* Reset zoom on click */
  }

      @media (max-width: 600px) {
    font-size: 14px;
    padding: 4px;
  }
`;

const WidthAdjuster = styled.div`
  width: 80%;
  margin: 10px auto;
  margin-top: 50px;
  max-width:1525px;
  
   @media (max-width: 600px) {
   width: 97%;
     margin-top: 30px;
  }
 
`;
const HeadingWithLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  
`;
const CarouselButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
 
 width: 6%;

 
  @media (max-width: 600px) {
    width: 21% !important;

  }

    @media (max-width: 1800px) {
    width: 8%;

  }

   @media (max-width: 1271px) {
    width: 11%;

  }

  
   @media (max-width: 990px) {
    width: 17%;

  }

     @media (max-width: 400px) {
    width:  26% !important;

  }
`;


const CustomPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    color: #00B4D8;
    border-color: #00B4D8;
  }
  .MuiPaginationItem-root.Mui-selected {
    background-color: #00B4D8;
    color: white;
    width:36px;
    height:36px;
    border-radius:19px;
    font-size:16px;
  }
  .MuiPaginationItem-ellipsis {
    color: #00B4D8;
  }
  .MuiPaginationItem-icon {
    color: #00B4D8;
  }
`;


const PaginationInfo = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color:#7B7B7B;
`;

const SliderWrapper = styled.div`
  margin-top: 40px;
  margin:auto;
`;
const ProductWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const ViewCenter = styled.div`
  display: flex;
  margin: 20px auto;
  justify-content: center;
  align-items: center;
`;

const PaginationUI = styled.div`
    margin: 74px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    padding: 10px 14px;
    border-top: 1.4px solid rgba(0, 0, 0, 0.2);

         @media (max-width: 600px) {
    width:  80% ;

  }

`;

// margin: "74px auto", 
// display: "flex", 
// alignItems: "center", 
// justifyContent: "space-between", 
// width: isMobile ? "80%" : "90%",
// padding:"10px 14px", 
// borderTop:"1.4px solid rgba(0, 0, 0, 0.2);"



function DisplaySubCategory({ setSelectedCategory }) {
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { userProfile } = await get("/api/auth/profile-info");
        dispatch(setUserDetails(userProfile || {}));
      } catch (e) {
        console.error("Error fetching user details:", e);
      }
    };

    fetchUserDetails();
  }, [dispatch]);
  const navigate = useNavigate()
  const user = useSelector((state) => state.general.user);
  const [wishlistItems, setWishlistItems] = useState([]);
  const RecentlyViewed = JSON.parse(localStorage.getItem('RecentlyViewed')) || [];
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component is mounted
  }, []);
  const location = useLocation();
  const { selectedCategory } = location.state || {};
  useEffect(() => {
    console.log("Selected Category:", selectedCategory);
  }, []);

  const [products, setproducts] = useState([]);

  const [indexTill, setIndexTill] = useState(8);  // Initially show only 4 categories
  const [viewAllClicked, setViewAllClicked] = useState(false);  // To control "View All" visibility

  useEffect(() => {
    console.log("Selected Category:", selectedCategory);
  }, [selectedCategory]);

  const handleViewAllClick = () => {
    if (selectedCategory?.subcategories.length > 8) {
      setViewAllClicked(true);
      setIndexTill(selectedCategory.subcategories.length);  // Show all subcategories
    }
  };

  async function getLandingPageDetails() {
    try {
      const data = await get("/api/ecom-dashboard/web");
      // setSliderImages(data?.sliderImages || []);
      // setAds(data?.sliderAds || []);
      setproducts(data?.propularProducts || []);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getLandingPageDetails();

  }, []);

  async function getUserWishlistItems() {
    if (user) {
      // Fetch wishlist items from API for logged-in users
      try {
        const items = await post("/api/wishlist", {});
        console.log("Fetched wishlist items (logged-in user):", items?.wishlist?.wishlist_items);
        setWishlistItems(items?.wishlist?.wishlist_items || []);
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    } else {
      // Fetch wishlist items from local storage for guest users
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      console.log("Fetched wishlist items (guest user):", storedWishlist);
      setWishlistItems(storedWishlist);
    }
  }


  // useEffect(() => {
  //   console.log("PopularProductSlider: wishlistItems updated:", wishlistItems);
  // }, [wishlistItems]);


  useEffect(() => {
    // Call this on initial render to load wishlist items
    getUserWishlistItems();
  }, []); // Run only once on mount

  useEffect(() => {
    console.log("ProductSlider: wishlistItems updated:", wishlistItems);
  }, [wishlistItems]);

  // console.log(selectedCategory.subcategories.length);
  // console.log(selectedCategory.subcategories.childCategories);


  useEffect(() => {

    // console.log("Selected Category:", selectedCategory); // Log dynamicPageContent
    // console.log("pr",products)

  }, []);

  const matches = useMediaQuery('(max-width:600px)');

  let sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    dots: false,
    infinite: false, // Disable infinite looping
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index), // Track the current slide
    responsive: [
      {
        breakpoint: 1479,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1036,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const slidesToShow =
    settings.responsive.find((resp) => window.innerWidth <= resp.breakpoint)
      ?.settings.slidesToShow || settings.slidesToShow;

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const totalSlides = products.length;

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const totalPages = Math.ceil(selectedCategory?.subcategories.length / itemsPerPage);
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentSubCategories = selectedCategory?.subcategories?.slice(indexOfFirstProduct, indexOfLastProduct);
  console.log("Check total:---", selectedCategory?.subcategories)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  console.log("Cehcking on SUb", selectedCategory)


  const handlePageChange = (event, value) => {
    setCurrentPage(value);


    window.scrollTo(0, 0) // Scroll to the top when the component is mounted

  };



  return (
    <WidthAdjuster>
      {/* <ViewEnd>
        <ArrowBackIcon onClick={() => setSelectedCategory(null)} />
        <GoBack onClick={() => {
          setSelectedCategory(null);
          dispatch(selectedCategoryFromLandingPage(null));
        }}>BACK</GoBack>
      </ViewEnd> */}
      {/* <ViewEnd onClick={handleBack}>
        <ArrowBackIcon />
        <GoBack>BACK</GoBack>
      </ViewEnd> */}

      <ViewEnd>
        <ArrowBackIconStyled
          onClick={handleBack}
        />
      </ViewEnd>
      <Heading>{currentSubCategories?.length > 0 ? selectedCategory?.name : null}</Heading>


      {currentSubCategories && currentSubCategories?.length > 0 ?
        <Grid container spacing={2} alignItems="center">
          {currentSubCategories?.map((item) => {
            return (
              <Grid
                item
                xs={6}
                md={4}
                key={item.id}  // Ensure each item has a unique key
                onClick={() => navigate("/child-category", { state: { selectedCategory, selectedSubCategory: item } })}
                style={{ cursor: "pointer" }}
              >
                <IndividualCategory name={item?.name} img={item?.image_url} />
              </Grid>
            );
          })}
        </Grid> : 
        
        
        
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // height: "70vh",
            // padding:"60px"


          }}
        >
          <img src={empty_category} alt="Empty Cart" style={{ width: "clamp(300px, 90%, 500px)" }} />
          <div style={{ display: "flex", alignItems: "center", flexDirection: "column", margin: "40px 0px" }}>
            <span style={{ fontFamily: "Inter", color: "#9D9D9D", fontSize: isMobile ? '28px' : "38px", fontWeight: "700", textAlign: "center" }}>
              No Sub Category
            </span>

            <span



              style=

              {{
                color: "#898989",
                fontSize: "18px",
                fontWeight: "500",
                fontFamily: "Inter",
                marginTop: "10px",


              }}
            >  Sorry no Sub Category
            </span>
            {/* <span></span> */}
          </div>
        </div>}




      {/* {!viewAllClicked && selectedCategory?.subcategories.length > 8 && (
        <ViewCenter>
          <Button
            onClick={handleViewAllClick}
            style={{
              borderRadius: "8px",
              width: "138px",
              height: "49px",
              color: "white",
              background: "#00B4D8",
              cursor: "pointer",
            }}
          >
            View All
          </Button>
        </ViewCenter>
      )} */}

      {selectedCategory?.subcategories?.length > 9 && (
        <PaginationUI>
          <PaginationInfo>
            {`Page ${currentPage} of ${totalPages}`}
          </PaginationInfo>
          <Stack spacing={2}>
            <CustomPagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                "& .MuiPaginationItem-root.Mui-selected": {
                  borderRadius: "6px !important",
                },


              }}
            />
          </Stack>
        </PaginationUI>
      )}





      <div style={{ margin: "50px auto" }}>

        <HeadingWithLink>
          <Heading2>Recent Viewed Products</Heading2>
          <CarouselButtonWrapper>
            {/* Left Arrow */}
            <ButtonWrapper
              onClick={previous}
              style={{ visibility: currentSlide === 0 ? "hidden" : "visible" }}
            >
              <ArrowBackIosIcon
                style={
                  matches
                    ? { fontSize: "12px", color: "#595959", paddingLeft: "10px" }
                    : { fontSize: "16px", color: "#595959", paddingLeft: "6px" }
                }
              />
            </ButtonWrapper>

            {/* Right Arrow */}
            <ButtonWrapper
              onClick={next}
              style={{
                visibility:
                  currentSlide >= totalSlides - slidesToShow
                    ? "hidden"
                    : "visible",
              }}
            >
              <ArrowForwardIosIcon
                style={
                  matches
                    ? { fontSize: "12px", color: "#595959" }
                    : { fontSize: "16px", color: "#595959" }
                }
              />
            </ButtonWrapper>
          </CarouselButtonWrapper>
        </HeadingWithLink>
        <SliderWrapper>
          <Slider ref={sliderRef} {...settings}>
            {RecentlyViewed?.map(item => {
              return <RecentlyProduct key={item.id} product={item} getUserWishlistItems={getUserWishlistItems} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} />
            })}
          </Slider>
        </SliderWrapper>
      </div>




    </WidthAdjuster>
  );
}

export default DisplaySubCategory;
