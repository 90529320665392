import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { post } from "../../api_helper/api_helper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { formatDollars } from "../../helper/generalFunctions"
import "./Default.css"
// import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogClose } from '@/components/ui/dialog'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Box,
  IconButton,

} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { use } from "react";
const CartBoxWidth = styled.div`
  padding: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #e3e3e3;
  margin: auto;

        @media (max-width: 400px) {
     padding: 18px 12px;
  }
`;

const CouponHeading = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 15px;
`;
// const CouponInput = styled.input`
//   width: 80%;
//   height: 40px;
//   text-align: start;
//   border: none;
//   outline: none;
//   padding : 0 15px;
//   background: #f3f3f3;
//   color: #838484;
// `;

const CouponInput = styled.input`
  width: 80%;
  height: 41px;
  text-align: start;
  font-size:16px;
    font-family: "Inter";
  padding : 0 15px;
  border: 1px solid #E3E3E3 ;
  outline: none;
  // background: #f3f3f3;
  color: #838484;
  // border-radius: 8px;
    border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;
// const ApplyBtn = styled.button`
//   outline: none;
//   border: none;
//   background: transparent;
//   color: #00b4d8;
//   font-family: "Inter";
//   font-size: 16px;
//   font-weight: 500;
//   line-height: 20px;
//   margin-left: 10px;
//   cursor: pointer;
// `;

const ApplyBtn = styled.button`
  outline: none;
    border: 1px solid #00B4D8;
    background: #DEFAFF;
    color: #19889F;
  // border-radius: 8px;
  padding:0 26px;
 height:43px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;


  font-family: "Inter";
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  // margin-left: 10px;
  cursor: pointer;

  //     @media (max-width: 423px) {
  // font-size: 14px;
  //   margin-left: 3px;
  //   padding: 8px 5px;
  // }
`;

const Title = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #242626;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Detail = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #242626;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const TitleSmall = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #838484;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const DetailSmall = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #838484;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const ProductCounter = styled.span`
  font-family: "Inter";
  font-size: 16px;
    color: #838484;
  font-weight: 400;
  line-height: 22px;
  margin-left: 10px;
`;
const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CheckoutBtn = styled.button`
  width: 100%;
  background: #00b4d8;
  color: white;
  border-radius: 8px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
`;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '10px',
    maxWidth: '400px',
    width: '100%',
  },
}));

const CouponBox = styled(Box)(({ theme }) => ({
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  padding: '16px',
  marginBottom: '16px',
}));

const ApplyButton = styled(Button)(({ theme }) => ({
  color: '#FF0000',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));


function CartDetailsBox({ getUserCartInfo, cart, coupon_code, setCouponCode }) {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.general.user);
  const [error, setError] = useState(null); // Error state
  const [couponss, setCoupons] = useState([]);
  const [Apply, SetApply] = useState(false)
  // console.log("Check Coupons:---- ", couponss)
  let TotalSub = cart?.subTotal;
  console.log("Sub Total", TotalSub)
  const [open, setOpen] = useState(false);

  // console.log("Hello User", cart)

  useEffect(() => {

    if (TotalSub) {

      fetchCoupons()


    }

  }, [TotalSub]);  // Empty dependency array ensures this runs once on mount

  const handleOpen = () => {
    if (!couponss || couponss.length === 0) {
      toast.error("You don't have coupons currently");  // Show the error toast
      setOpen(false);  // Ensure the popup is not open
    } else {
      setOpen(true);  // Open the popup if there are coupons
    }
  };


  // const handleOpen = async () => {
  //   setOpen(true);  // Show the promo code section
  //   await fetchCoupons();  // Fetch coupons when the section is opened
  // };
  const handleClose = () => setOpen(false);

  async function fetchCoupons() {
    try {
      setLoading(true);  // Set loading to true while fetching data
      console.log("Sub toal", TotalSub)
      const response = await post('/api/user-coupons', {
        user_id: user?.id,  // Replace with actual user ID
        for_shipment: false,   // Set the boolean value you want to pass
        sub_total: TotalSub,
      });

      // console.log("Hello Coupons", response)

      setCoupons(response);  // Set the fetched coupons data to state



      setLoading(false);  // Set loading to false after fetching
      // toast.success('Promo codes fetched successfully!'); 
    } catch (error) {
      setLoading(false);  // Set loading to false if an error occurs
      // toast.error(error?.response?.data?.error || 'Something went wrong'); 
      console.log(error);
    }
  }

  console.log("Hello Coupens", couponss)

  // const coupons = [
  //   {
  //     code: '#COUPON2024',
  //     description: 'Flat 25% off discount',
  //     details: [
  //       'Discount/Offer: 25% off your entire purchase',
  //       'Minimum Purchase: (Optional) Spend $50 or more to redeem this offer.',
  //       'Expiration Date: This coupon expires on May 15th, 2024.',
  //     ],
  //   },
  //   {
  //     code: '#COUPON2024',
  //     description: 'Flat 25% off discount',
  //     details: [
  //       'Discount/Offer: 25% off your entire purchase',
  //       'Minimum Purchase: (Optional) Spend $50 or more to redeem this offer.',
  //       'Expiration Date: This coupon expires on May 15th, 2024.',
  //     ],
  //   },
  //   {
  //     code: '#COUPON2024',
  //     description: 'Flat 25% off discount',
  //     details: [
  //       'Discount/Offer: 25% off your entire purchase',
  //       'Minimum Purchase: (Optional) Spend $50 or more to redeem this offer.',
  //       'Expiration Date: This coupon expires on May 15th, 2024.',
  //     ],
  //   },
  // ];



  const [showRemove, setShowRemove] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState("");


  const grandShippingValue = useSelector(
    (state) => state.general.grandShippingValue
  );
  const selectedAddress = useSelector(
    (state) => state.general.selectedDeliveryAddress
  );
  const isBuyNowItemSelected = useSelector(
    (state) => state.general.isBuyNowItemSelected
  );

  console.log("Hello there cart prices Grand Cost:-  ", cart?.shippingGrandCost)
  console.log("Hello there cart prices Grand Shipping Value:- ", grandShippingValue)

  const subTotal = cart?.subTotal || 0;
  const shipping = grandShippingValue || cart?.shippingGrandCost;
  const taxes = cart?.taxes || 0;
  const discount = cart?.discountAmount || 0;
  // console.log("Discounted Price", discount)

  const GrandTotalValue = subTotal + shipping + taxes - discount;



  const Products = cart?.cartItems;




  console.log("E-commerce-Order", selectedAddress)

  async function createOrder() {
    try {
      setLoading(true);
      let url = "";
      console.log("Parameters 1", url)
      if (isBuyNowItemSelected) {
        const orderIds = Products?.map(item => item?.product_id);
        const info = await post("/api/create-ecommerce-order/buy-now", {
          ...(coupon_code && { coupon_code }),
          user_id: user?.id,
          orderIds: orderIds,
          delivery_address_id: selectedAddress?.id,

        });
        url = info.url;
        console.log("Parameters 2", url)
      } else {
        const info = await post("/api/create-ecommerce-order", {
          ...(coupon_code && { coupon_code }),
          user_id: user?.id,

          delivery_address_id: selectedAddress?.id,
        });
        url = info?.url;
        console.log("Parameters 3", url)
      }

      setLoading(false);
      // console.log("Main URL,", url)
      window.location.href = url;
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error || "something went wrong");
      console.log(e);
    }
  }

  // console.log("Coupon Code Is Here:- ", coupon_code)


  //Working


  // async function checkAndApplyCoupon() {
  //   try {
  //     if (showRemove) {
  //       setCouponCode(""); // Clear the coupon code
  //       setShowRemove(false);
  //       getUserCartInfo(true);
  //       return;
  //     }

  //     setLoading(true);

  //     // Use the coupon_code entered by the user
  //     const couponToApply = coupon_code;
  //     console.log("Checking Couponsss",couponToApply)

  //     if (!couponToApply) {
  //       toast.error("Please enter a valid coupon code.");
  //       setLoading(false);
  //       return;
  //     }

  //     // Make the API call with the entered coupon code
  //     await post("/api/get-coupon-by-name-shipment", {
  //       coupon_name: couponToApply, // Pass the user-input coupon code
  //       grandTotal: cart?.grandTotal || 0,
  //       order_type: "ECOMMERCE",
  //       user_id: user?.id
  //     });

  //     SetApply(!Apply);
  //     setLoading(false);
  //     toast.success("Coupon applied!");

  //     setOpen(false);

  //     getUserCartInfo();
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(e?.response?.data?.message || "Something went wrong");
  //     SetApply(!Apply);
  //     console.log(e);
  //   }
  // };

  // console.log("Input Field", coupon_code  )



  async function checkAndApplyCoupon() {
    try {
      // if (showRemove) {
      //   setCouponCode(""); // Clear the coupon code
      //   setShowRemove(false); // Set showRemove to false
      //   SetApply(false); // Set Apply to false
      //   getUserCartInfo(true);
      //   return;
      // }

      if (!coupon_code) {
        toast.error("Please select a valid coupon code");
        return false;
      }

      if (appliedCoupon === coupon_code) {
        setCouponCode(""); // Clear the coupon code
        setAppliedCoupon(""); // Remove the applied coupon
        setShowRemove(false); // Hide the remove button
        toast.success("Coupon Removed");
        getUserCartInfo(true); // Refresh cart details
        console.log("Hello There Inside Check and Apply")

        return;
      }

      setLoading(true);

      const couponToApply = coupon_code;
      console.log("Checking Coupon: ", couponToApply);

      if (!couponToApply) {
        toast.error("Please enter a valid coupon code.");
        setLoading(false);
        return;
      }

      await post("/api/get-coupon-by-name-shipment", {
        coupon_name: couponToApply,
        grandTotal: cart?.grandTotal || 0,
        order_type: "ECOMMERCE",
        user_id: user?.id
      });
      console.log("Hello CheckAndApply")

      setAppliedCoupon(couponToApply); // Set this as the currently applied coupon

      setShowRemove(true);
      SetApply(true);
      setLoading(false);
      toast.success("Coupon applied!");
      setOpen(false);
      getUserCartInfo();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "Something went wrong");
      console.log(e);
    }
  };



  //Working

  // const handleApplyCoupon = async (coupon_name) => {
  //   try {
  //     if (showRemove) {
  //       setCouponCode(""); // Remove the coupon code
  //       setShowRemove(false);
  //       getUserCartInfo(true);
  //       return;
  //     }

  //     setLoading(true);

  //     // Set the coupon code to the selected coupon's name
  //     setCouponCode(coupon_name); 
  //     console.log("Coupon Name is : - ", coupon_name)
  //     // Pass the selected coupon's coupon_name to the API
  //     await post("/api/get-coupon-by-name-shipment", {
  //       coupon_name: coupon_name, // Pass the coupon_name from the current coupon
  //       grandTotal: cart?.grandTotal || 0,
  //       order_type: "ECOMMERCE",
  //       user_id: user?.id
  //     });

  //     SetApply(!Apply);
  //     setLoading(false);
  //     toast.success("Coupon applied!");


  //     setOpen(false);

  //     getUserCartInfo();
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(e?.response?.data?.message || "Something went wrong");
  //     SetApply(!Apply);
  //     console.log(e?.response);
  //   }
  // };

  // console.log("Current Coupon",appliedCoupon)

  const handleApplyCoupon = async (coupon_name) => {
    try {
      // If the current coupon is already applied, remove it
      if (appliedCoupon === coupon_name) {
        setCouponCode(""); // Clear the coupon code
        setAppliedCoupon(""); // Remove the applied coupon
        setShowRemove(false); // Hide the remove button
        toast.success("Coupon Removed");
        setOpen(false);
        getUserCartInfo(true); // Refresh cart details
        console.log("Hello HandleApply")
        return;
      }

      // Apply a new coupon
      setLoading(true);
      setCouponCode(coupon_name); // Set the selected coupon's name
      console.log("Coupon Name is: ", coupon_name);

      // API call to validate and apply the coupon
      await post("/api/get-coupon-by-name-shipment", {
        coupon_name: coupon_name,
        grandTotal: cart?.grandTotal || 0,
        order_type: "ECOMMERCE",
        user_id: user?.id
      });
      // console.log("Hello HandleApply")
      setAppliedCoupon(coupon_name); // Set this as the currently applied coupon
      setShowRemove(true); // Show the "REMOVE" button for the applied coupon
      setLoading(false);
      toast.success("Coupon applied!");
      setOpen(false);
      getUserCartInfo(false, coupon_name);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "Something went wrong");
      console.log(e);
    }
  };



  //   useEffect(() => {
  //   if (coupon_code) {


  //     checkAndApplyCoupon(coupon_code); 
  //   }
  // }, [coupon_code]);

  //   const handleApplyCoupon = (coupon_name) => {
  //     if (showRemove) {
  //       setCouponCode(""); 
  //       setShowRemove(false);
  //       getUserCartInfo(true);
  //       return;
  //     }



  //     setLoading(true);
  //     setCouponCode(coupon_name); 
  //   };

  //   async function checkAndApplyCoupon(couponToApply) {
  //     try {
  //       if (showRemove) {
  //         setCouponCode(""); 
  //         setShowRemove(false);
  //         getUserCartInfo(true);
  //         return;
  //       }

  //       console.log("Checking Couponsss", couponToApply);

  //       if (!couponToApply) {
  //         toast.error("Please enter a valid coupon code.");
  //         setLoading(false);
  //         return;
  //       }

  //       await post("/api/get-coupon-by-name-shipment", {
  //         coupon_name: couponToApply, 
  //         grandTotal: cart?.grandTotal || 0,
  //         order_type: "ECOMMERCE",
  //         user_id: user?.id
  //       });

  //       SetApply(true);
  //       setLoading(false);
  //       toast.success("Coupon applied!");

  //       setOpen(false);
  //       getUserCartInfo();
  //     } catch (e) {
  //       setLoading(false);
  //       toast.error(e?.response?.data?.message || "Something went wrong");
  //       SetApply(false);
  //       console.log(e);
  //     }
  //   }





  // async function checkAndApplyCoupon() {
  //   try {
  //     if (showRemove) {
  //       // If the coupon is applied and the user wants to remove it
  //       setCouponCode(""); // Clear the coupon code
  //       setAppliedCoupon(null); // Clear applied coupon
  //       setShowRemove(false); // Hide REMOVE option
  //       getUserCartInfo(true); // Refresh the cart info
  //       return;
  //     }

  //     setLoading(true);

  //     const couponToApply = coupon_code;

  //     if (!couponToApply) {
  //       toast.error("Please enter a valid coupon code.");
  //       setLoading(false);
  //       return;
  //     }

  //     // Make the API call with the entered coupon code
  //     await post("/api/get-coupon-by-name-shipment", {
  //       coupon_name: couponToApply,
  //       grandTotal: cart?.grandTotal || 0,
  //       order_type: "ECOMMERCE",
  //       user_id: user?.id,
  //     });

  //     setAppliedCoupon(couponToApply); // Set applied coupon
  //     setShowRemove(true); // Show the REMOVE option
  //     setLoading(false);
  //     toast.success("Coupon applied!");

  //     setOpen(false);

  //     getUserCartInfo();
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(e?.response?.data?.message || "Something went wrong");
  //     setAppliedCoupon(null);
  //     setShowRemove(false);
  //     console.log(e);
  //   }
  // }

  // const checkAndApplyCoupon = async (coupon_name = null) => {
  //   try {
  //     if (showRemove) {
  //       // If the coupon is applied and the user wants to remove it
  //       setCouponCode(""); // Clear the coupon code
  //       setAppliedCoupon(null); // Clear applied coupon
  //       setShowRemove(false); // Hide REMOVE option
  //       getUserCartInfo(true); // Refresh the cart info
  //       return;
  //     }

  //     setLoading(true);
  //     // console.log("Coupon Name: ",coupon_name)
  //     // console.log("Coupon Code: ",coupon_code)

  //     const couponToApply = coupon_name || coupon_code; // Prioritize argument, fallback to state

  //     if (!couponToApply) {
  //       toast.error("Please enter a valid coupon code.");
  //       setLoading(false);
  //       return;
  //     }

  //     // Make the API call with the entered or selected coupon code
  //     await post("/api/get-coupon-by-name-shipment", {
  //       coupon_name: couponToApply,
  //       grandTotal: cart?.grandTotal || 0,
  //       order_type: "ECOMMERCE",
  //       user_id: user?.id,
  //     });

  //     setAppliedCoupon(couponToApply); // Set applied coupon
  //     setCouponCode(couponToApply); // Set the input value to match the applied coupon
  //     setShowRemove(true); // Show the REMOVE option
  //     setLoading(false);
  //     toast.success("Coupon applied!");

  //     setOpen(false);
  //     getUserCartInfo();
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(e?.response?.data?.message || "Something went wrong");
  //     setAppliedCoupon(null);
  //     setShowRemove(false);
  //     console.log(e);
  //   }
  // };


  // const handleApplyCoupon = async (coupon_name) => {
  //   try {
  //     if (showRemove) {
  //       // If the REMOVE button is clicked, clear the coupon
  //       setCouponCode(""); // Remove the coupon code
  //       setAppliedCoupon(null); // Remove applied coupon
  //       setShowRemove(false); // Hide the REMOVE option
  //       getUserCartInfo(true); // Refresh the cart info
  //       return;
  //     }

  //     setLoading(true);

  //     // Set the coupon code to the selected coupon's name
  //     setCouponCode(coupon_name); // Set the coupon code here

  //     // Pass the selected coupon's coupon_name to the API
  //     await post("/api/get-coupon-by-name-shipment", {
  //       coupon_name: coupon_name, // Pass the coupon_name from the current coupon
  //       grandTotal: cart?.grandTotal || 0,
  //       order_type: "ECOMMERCE",
  //       user_id: user?.id,
  //     });

  //     setAppliedCoupon(coupon_name); // Set applied coupon
  //     setShowRemove(true); // Show the REMOVE option
  //     setLoading(false);
  //     toast.success("Coupon applied!");

  //     setOpen(false);

  //     getUserCartInfo();
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(e?.response?.data?.message || "Something went wrong");
  //     setAppliedCoupon(null);
  //     setShowRemove(false);
  //     console.log(e?.response);
  //   }
  // };



  console.log("Apply or not", Apply)

  const getExpirationDateDetails = (expirationDate) => {
    const date = new Date(expirationDate);

    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'long' }); // Full month name
    const day = date.getDate();

    return { year, month, day };
  };

  return (
    <CartBoxWidth>

      <StyledDialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '550px !important',
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                margin: 'auto !important',
                fontSize: '32px !important',
                fontFamily: 'Inter',
              }}
            >
              Coupons
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {couponss.map((coupon, index) => {

            const { year, month, day } = getExpirationDateDetails(coupon.expiration_date);
            return (
              <CouponBox
                key={index}
                sx={{
                  border: '1px solid #A3B9FF !important',
                  borderRadius: '8px !important',
                  marginBottom: 2,
                  padding: 2,
                }}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    fontWeight="bold"
                    sx={{
                      fontFamily: 'Inter !important',
                      fontSize: '16px !important',
                      fontWeight: 400,
                      color: '#334375 !important',
                      border: '1px dotted #334375 !important',
                      padding: '4px !important',
                      borderRadius: '5px !important',
                      backgroundColor: '#D3DEFF !important',
                    }}
                  >
                    #{coupon?.coupon_name}
                  </Typography>

                  {/* Working */}

                  {/* <ApplyButton
                    disabled={loading}
                    
                    onClick={() => handleApplyCoupon(coupon?.coupon_name)}
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px !important',
                      fontFamily: 'Inter !important',
                    }}
                  >
                    {Apply ? (showRemove ? `REMOVE` : `Applied`) : (showRemove ? `REMOVE` : `Apply`)}
                  </ApplyButton> */}

                  <ApplyButton
                    disabled={loading}
                    onClick={() => handleApplyCoupon(coupon?.coupon_name)}
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px !important',
                      fontFamily: 'Inter !important',
                    }}
                  >
                    {appliedCoupon === coupon?.coupon_name ? 'REMOVE' : 'Apply'}
                  </ApplyButton>

                  {/* <ApplyButton
                    disabled={loading}
                    onClick={() => checkAndApplyCoupon(coupon?.coupon_name)}
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px !important',
                      fontFamily: 'Inter !important',
                    }}
                  >
                    {appliedCoupon === coupon?.coupon_name || (showRemove && coupon?.coupon_name === coupon_code)
                      ? (showRemove ? 'REMOVE' : 'Applied')
                      : 'Apply'}
                  </ApplyButton> */}
                </Box>
                <Typography
                  variant="body2"
                  color="success.main"
                  mb={1}
                  sx={{
                    fontFamily: 'Inter !important',
                    color: '#3BC651 !important',
                    fontSize: '15px !important',
                    fontWeight: 400,
                  }}
                >
                  {`${coupon?.coupon_description}`}
                </Typography>


                {Object.keys(coupon)
                  .filter(key => key.toLowerCase().includes('discription')) // Filter out all the description keys
                  .map((key, index) => (
                    coupon[key] && ( // Ensure that description is not empty
                      <Typography
                        key={index}
                        variant="body2"
                        color="text.secondary"
                        fontSize="12px"
                        sx={{
                          color: '#999999 !important',
                          fontFamily: 'Inter !important',
                          fontSize: '13px !important',
                          fontWeight: 400,
                        }}
                      >
                        • {coupon[key]}
                      </Typography>
                    )
                  ))
                }

                {/* <Typography
                  variant="body2"
                  color="text.secondary"
                  fontSize="12px"
                  sx={{
                    color: '#999999 !important',
                    fontFamily: 'Inter !important',
                    fontSize: '13px !important',
                    fontWeight: 400,
                  }}
                >
                  • {`Discount/Offer: ${coupon?.amount}% off your entire purchase`}
                </Typography>

                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontSize="12px"
                  sx={{
                    color: '#999999 !important',
                    fontFamily: 'Inter !important',
                    fontSize: '13px !important',
                    fontWeight: 400,
                  }}
                >
                  • {`Minimum Purchase: (Optional) Spend ${coupon?.min_order_amount}$ or more to redeem this offer.`}
                </Typography>

                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontSize="12px"
                  sx={{
                    color: '#999999 !important',
                    fontFamily: 'Inter !important',
                    fontSize: '13px !important',
                    fontWeight: 400,
                  }}
                >
                  • {`Expiration Date: This coupon expires on ${month} ${day}, ${year}. `}
                </Typography> */}

              </CouponBox>
            );
          })}


        </DialogContent>
      </StyledDialog>

      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <CouponHeading>Promo Code</CouponHeading>


        {/* <div style={{ display: "flex", justifyContent: "right", width: "100%", marginTop: "14px", borderBottom: "1px dotted black", paddingBottom: "14px" }}> */}
        <span style={{ fontSize: "14px", color: "#0066FF", cursor: "pointer", fontWeight: "500", fontFamily: "Inter" }} onClick={handleOpen}>View promo codes</span>
      </div>
      <BoxWrapper style={{ justifyContent: "normal" }}>
        <CouponInput
          placeholder="#2056986"
          type="text"
          value={coupon_code}
          onChange={(e) => {
            setCouponCode(e.target.value);
          }}
        />

        {/* <ApplyBtn disabled={loading} onClick={checkAndApplyCoupon}>
          {Apply ? showRemove ? `REMOVE` : `Applied` : showRemove ? `REMOVE` : `Apply`}
        </ApplyBtn> */}

        <ApplyBtn disabled={loading} onClick={checkAndApplyCoupon}>
          {appliedCoupon === coupon_code && showRemove ? 'REMOVE' : 'Apply'}
        </ApplyBtn>
        {/* <ApplyBtn
          disabled={loading}
          onClick={() => checkAndApplyCoupon()} // Call without argument for manual input
        >
          {appliedCoupon ? (showRemove ? "REMOVE" : "Applied") : (showRemove ? "REMOVE" : "Apply")}
        </ApplyBtn> */}

      </BoxWrapper>



      <div style={{ marginTop: "20px" }}></div>

      <BoxWrapper style={{ marginBottom: "10px" }}>
        <Title>Product Total</Title>
        {/* {console.log("Hello There, It's Coupon Code. ")} */}
        <Detail>{formatDollars(cart?.subTotal)}</Detail>
      </BoxWrapper>
      <BoxWrapper style={{ marginBottom: "10px" }}>
        <TitleSmall>Discount</TitleSmall>
        <DetailSmall>-{formatDollars(cart?.discountAmount)}</DetailSmall>
        {console.log("Discount Ammount", cart)}
      </BoxWrapper>
      <BoxWrapper style={{ marginBottom: "10px" }}>
        <TitleSmall>Shipping Charges</TitleSmall>
        <DetailSmall>{formatDollars(shipping)}</DetailSmall>
      </BoxWrapper>
      <BoxWrapper style={{ marginBottom: "10px" }}>
        <TitleSmall>Taxes</TitleSmall>
        <DetailSmall>{formatDollars(cart?.taxes)}</DetailSmall>
      </BoxWrapper>
      <div
        style={{ border: "1px solid #AFAFAF", margin: "10px 0", width: "100%" }}
      ></div>
      <BoxWrapper>
        <div style={{ display: "flex" }}>
          <TitleSmall>Total</TitleSmall>{" "}
          <ProductCounter>
            {` ( ${cart?.cartItems?.length || 0} products )`}
          </ProductCounter>
        </div>
        <Detail style={{ color: "#191A26", fontWeight: "800" }}>{formatDollars(GrandTotalValue)}</Detail>
      </BoxWrapper>
      <CheckoutBtn onClick={createOrder}>Checkout</CheckoutBtn>
    </CartBoxWidth>
  );
}

export default CartDetailsBox;
