import React from "react"
import styled from "@emotion/styled"
import Cartitem from "./Cartitem"

const CartBoxWidth = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
@media (max-width: 800px) {
    width: 100%;
    /* max-height: 500px;
    overflow-x: auto; */
  }
`



function CartItemsBox({cart,getUserCartInfo,hide}){
    return (
        <CartBoxWidth>

            {cart?.cartItems?.map(item=>{

                return (

                    <Cartitem hide={hide} item={item} getUserCartInfo={getUserCartInfo} cart={cart}/>
                )
            })}

        </CartBoxWidth>
    )
}

export default CartItemsBox