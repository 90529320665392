import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import AirplanemodeActiveTwoToneIcon from "@mui/icons-material/AirplanemodeActiveTwoTone";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import {
  selectedCartItem,
  showUpdateCartItemShipment,
} from "../../store/reducer/reducer";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { post } from "../../api_helper/api_helper";
import { CircularProgress, Grid } from "@mui/material";
import CloseModal from "../Auth/CloseModal";
import { formatDollars } from "../../helper/generalFunctions";
import { useMediaQuery } from "@mui/material";
const LoginFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  background: #FFFFFF;
  width: clamp(127px, 90%, 539px);
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  border-radius: 16px;
  // width: 40%;
  padding: 20px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    width: 90%;
  }
`;

const Card = styled.div`
  background: white;
  width: 167px;
  height: 209;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px auto;
  box-sizing: border-box;
  border-radius: 4px;

        @media (max-width: 484px) {
    width: 128px;
    
  }
`;

const CardPrice = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: center;
  margin: 10px;

  
        @media (max-width: 484px) {
    font-size: 18px;
    margin: 5px;
    
  }
`;

const CardBtn = styled.div`
  width: 143px;
  height: 43px;
  border-radius: 20px;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;

    
        @media (max-width: 484px) {
    width: 98px;
    height: 32px;
    margin: 5px auto;
        font-size: 13px;
  }
`;

const CardSubText = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  margin: 20px;
`;
// const CardPrice = styled.div`
//   font-family: "Inter";
//   font-size: 20px;
//   font-weight: 400;
//   line-height: 24.2px;
//   text-align: center;
//   margin: 10px;
// `;
// const CardBtn = styled.div`
//   width: 187px;
//   height: 55px;
//   border-radius: 10px;
//   text-align: center;
//   font-family: "Inter";
//   font-size: 20px;
//   font-weight: 400;
//   line-height: 24.2px;
//   margin: 10px auto;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;
const ModalBtn = styled.div`
  height: 51px;
  background: #00b4d8;
  color: white;
  font-family: "Inter";
  font-size: 20px;
  margin: 20px auto;
  cursor: pointer;
  font-weight: 400;
  line-height: 18px;
  width: 70%;
  border-radius: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
      @media (max-width: 484px) {
    height: 44px;
        width: 67%;
            font-size: 17px;
                margin: 12px auto 0 auto;
    
  }

`;
const Heading = styled.div`
  font-family: "Inter";
  font-size: 20px;
  line-height: 29.05px;
  font-weight: 500;
  display: flex;
      justify-content: center;
  // margin-bottom: 20px;
    margin-bottom: 7px;

      @media (max-width: 484px) {
font-size: 13px;
    
  }
`;
function UpdateTypeOfShipment({ getUserCartInfo , cart , setCart}) {
  const displayModal = useSelector(
    (state) => state.general.showUpdateCartItemShipment
  );
  const [selected, setSelected] = useState("AIR");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.general.user);
  const [shippingDetails, setShippingDetails] = useState({});
  const choosenProduct = useSelector((state) => state.general.selectedCartItem);
  const [loading, setLoading] = useState(false);
// Retrieve the stored cart from localStorage


  async function getShippingDetails() {
    try {
      const shippingDetails = await post("/api/ecom-calculate-shipping", {
        productId: choosenProduct?.product,
        ...(choosenProduct?.variant && {
          productVariantId: choosenProduct?.variant,
        }),
      });

      setShippingDetails(shippingDetails);
    } catch (e) {
      console.log(e);
    }
  }

  console.log("hello to the world",choosenProduct)

  useEffect(() => {
    if (choosenProduct) {
      getShippingDetails();
    }
  }, [choosenProduct]);

  console.log("Shipment Cart",cart)

  async function updateShipmentType() {
    try {
      setLoading(true);
      await post(`/api/cart/update-cart-item`, {
        cart_item: choosenProduct?.cart,
        shipment_type: selected,
      });
      toast.success("Updated!");
      setLoading(false);
      closeModal();
      getUserCartInfo();
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message || "something went wrong");
      console.log(e);
    }
  }
  function closeModal() {
    dispatch(showUpdateCartItemShipment(false));
    dispatch(selectedCartItem(null));
  }

  const selectedCartId = choosenProduct?.cart;
  const selectedCartItems = cart?.cartItems?.filter(item => item.id === selectedCartId);
  let quantity=selectedCartItems ? selectedCartItems[0]?.quantity : null;
  console.log(selectedCartItems)
  return (
    <>
      <Modal
        open={displayModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LoginFormWrapper>
          <CloseModal closeFn={closeModal} />
          <Heading>Select Type of Shipment</Heading>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item alignItems={"center"} justifyContent={"center"}>
              <DisplayShipmentCard
                iconName="Airplane"
                price={shippingDetails?.amountAir?formatDollars(shippingDetails?.amountAir * quantity):"--"}
                selected={selected}
                icon="AIR"
                name={"AIR"}
                duration={shippingDetails?.durationAir}
                setSelected={setSelected}
                qty={quantity}
              />
            </Grid>
            <Grid item alignItems={"center"} justifyContent={"center"}>
              <DisplayShipmentCard
                iconName="Ship"
                price={shippingDetails?.amountShip?formatDollars(shippingDetails?.amountShip * quantity):"--"}
                selected={selected}
                icon="SHIP"
                name={"SHIP"}
                duration={shippingDetails?.durationShip}
                setSelected={setSelected}
                qty={quantity}
              />
            </Grid>
          </Grid>
          <ModalBtn
            onClick={() => {
              updateShipmentType();
            }}
          >
            {loading ? <CircularProgress /> : `Submit`}
          </ModalBtn>
        </LoginFormWrapper>
      </Modal>
    </>
  );
}
function DisplayShipmentCard({
  iconName,
  icon,
  name,
  duration,
  selected,
  setSelected,
  price,
  qty
}) {
  const Icon =
    icon === "AIR"
      ? AirplanemodeActiveTwoToneIcon
      : DirectionsBoatFilledOutlinedIcon;
  const cardColor = selected === name ? `#03BEE7` : `#838484`;
  const textColor = selected === name ? `#242626` : `#838484`;
  const btnTextColor = selected === name ? `white` : `#838484`;
  const btnBackgroundColor = selected === name ? `#03BEE7` : `#E3E3E3`;

  const isMobile = useMediaQuery("(max-width:484px)"); 
  return (
    <Card
      style={{
        border:
          selected === name ? `1.5px solid #03BEE7` : "1.5px solid #E3E3E3",
      }}
      onClick={() => {
        setSelected(name);
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "7px",
        }}
      >
        <Icon
          style={{
            color: cardColor,
            fontFamily: "Inter",
            fontSize: isMobile ? "17px" : "30px", // Adjust font size based on screen width
            fontWeight: 500,
            lineHeight: "24.2px",
          }}
        />
        <div
          style={{
            color: cardColor,
            marginLeft: "10px",
            fontFamily: "Inter",
            fontSize: isMobile ? "17px" : "20px", // Adjust font size based on screen width
            fontWeight: 500,
            lineHeight: "24.2px",
          }}
        >
          {iconName}
        </div>
      </div>
      {/* <CardSubText style={{ color: textColor, textAlign: "center" }} /> */}
      <CardPrice style={{ color: textColor }}>{price }</CardPrice>

      <CardBtn style={{ color: btnTextColor, background: btnBackgroundColor }}>
        {duration}
      </CardBtn>
    </Card>
  );
}
export default UpdateTypeOfShipment;
