import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ProductDescription from "./ProductDescription";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { useSelector } from "react-redux";
import { get, post } from "../../api_helper/api_helper";
import FavoriteIcon from "@mui/icons-material/Favorite";

import { toast } from "react-toastify";
const ShowCaseWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50%;
  flex-direction: column;
  position:relative;
 
  @media (max-width: 600px) {
    width: 100%;
    // margin-bottom: 20px;
  }

   @media (max-width: 820px) {
    width: 100%;
    // margin-bottom: 20px;
  }

   @media (max-width: 1100px) {
    width: 100%;

  }
`;
const HeroImageWrapper = styled.div`
  width: 90%;
  max-height: 100%;
//  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position:relative;
  min-height: 605px;

    @media (max-width: 600px) {
 min-height:290px;
  }
`;
const ShowCaseSmallImages = styled.div`
  // overflow-x: auto;
  width: 100%;
  max-width: 90%; /* Set max-width to prevent expanding beyond a certain width */
  gap: 10px;
  margin: 10px auto;
  display: flex; /* Keep display: flex; to display images side by side */
  flex-wrap: nowrap;
`;
const SmallerImages = styled.div`
  width: 135px;
  height: 111px;
  gap: 0px;
  border-radius: 8px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  min-height: 90px;
  flex: 0 0 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SmallImageStyle = styled.img`
  width: 50px;
  cursor: pointer;
`;
const HeroImageStyle = styled.img`
height: 100%;
  max-height: 605px;
  // object-fit: cover;
  width : 80%
`;
const PositionAbsolute = styled.div`
position: absolute;
right: 7px;
top: 10px;
z-index: 1000;
cursor:pointer;

color:black;
`;

const InStock = styled.div`
    font-size: 15px;
    font-family: "Inter";
    font-weight: 400;
    padding: 8px 23px;
    border-radius: 6px;
    color: #4CBB6C;
    background: #E9F9EE;
    border: 1px solid #4CBB6C;


`;
function ProuductShowCase({ selectedVariant, product, getUserWishlistItems, setWishlistItems, wishlistItems, notMatched }) {
  // console.log("Checking the selected Variant", selectedVariant)
  const selectImages = product?.contain_variants
    ? selectedVariant
      ? product?.product_variants?.find((elem) => elem.id === selectedVariant)?.image_urls || product?.image_urls
      : product?.product_variants[0]?.image_urls
    : product?.image_urls;
  // const selectImages = productSelected?.contain_variants
  //   ? selectedVariant
  //     ? productSelected?.product_variants?.find((elem) => elem.id === selectedVariant)?.image_urls
  //     : productSelected?.product_variants?.length > 0
  //       ? productSelected.product_variants[0].image_urls
  //       : undefined
  //   : product?.image_urls;
  const user = useSelector((state) => state.general.user);
  // console.log("Check DATAS",productSelected)
  const bool = wishlistItems?.find(el => {
    // Base product check
    if (!notMatched && !el?.product_variant_id) {
      return el?.product?.id === product?.id;
    }

    // Variant check
    if (notMatched) {
      return (
        el?.product?.id === product?.id &&
        el?.product_variant_id === selectedVariant
      );
    }

    // Default (if no variant specified, check base product only)
    return el?.product?.id === product?.id && !el?.product_variant_id;
  });

  const [images, setImages] = useState(selectImages || []);
  const [selectedImage, setSelectedImage] = useState(
    images?.length > 0 ? images[0] : null
  );
  // console.log("Related to IMG", selectedImage)

  // useEffect(() => {
  //   setImages(selectImages || []);
  //   setSelectedImage(selectImages?.length > 0 ? selectImages[0] : null);
  // }, [selectedVariant]);

  useEffect(() => {
    // Log when useEffect fires
    // console.log("useEffect triggered. Updating images and selectedImage");

    // Update images and selectedImage whenever selectImages changes
    if (selectImages !== images) {
      setImages(selectImages || []);
      setSelectedImage(selectImages?.length > 0 ? selectImages[0] : null);
    }
  }, [selectImages]); // Dependency array includes selectImages to update when it changes

  useEffect(() => {
    console.log("Updated selectedImage:", selectedImage);
  }, [selectedImage]);  // Log selectedImage changes
  // console.log("Current selectedVariant:", selectedVariant);
  // console.log("Current product:", product);
  // console.log("Calculated selectImages:", selectImages);
  // console.log("Initial images state:", images);
  // console.log("Initial selectedImage state:", selectedImage);

  console.log("Items of Total Wishlist", wishlistItems)
  console.log("Total Products Details", product)
  return (
    <ShowCaseWrapper>


      <HeroImageWrapper>
        <HeroImageStyle
          key={selectedImage}  // Force re-render when selectedImage changes
          src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${selectedImage}?${new Date().getTime()}`}  // Adding timestamp to avoid cache issues
          alt="hero"
        />

        <HeartIcon bool={bool} item={product} getUserWishlistItems={getUserWishlistItems} notMatched={notMatched} user={user} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} selectedVariant={selectedVariant} />
      </HeroImageWrapper>
      <ShowCaseSmallImages>
        {images
          .filter((el) => el !== selectedImage)
          .map((el) => {
            return (
              <SmallerImages
                onClick={() => {
                  setSelectedImage(el);
                }}
              >
                <SmallImageStyle
                  src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${el}`}
                  alt="hero-small"
                />
              </SmallerImages>
            );
          })}
      </ShowCaseSmallImages>

      {/* <ProductDescription product={product} /> */}
    </ShowCaseWrapper>
  );
}

function HeartIcon({ bool, item, getUserWishlistItems, user, setWishlistItems, wishlistItems, selectedVariant, notMatched }) {
  console.log("not Matched is", notMatched)

  // {console.log(item)}
  const [loading, setLoading] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(bool); // Track if the item is in the wishlist
  // console.log("Inside the Product Details Page", item)
  // console.log("Whishlist Items", wishlistItems)
  let brand_name;
  const calculateDiscountPercentage = (item) => {
    if (item.discountedPrice && item.MRP) {
      return Math.round(((item.MRP - item.discountedPrice) / item.MRP) * 100);
    }
    return 0; // Return 0 if no discount is available
  };

  // Sync wishlist state with localStorage or server when component mounts
  // useEffect(() => {
  //   if (!user) {
  //     // For guest users, check localStorage
  //     const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  //     const isItemInWishlist = storedWishlist.some(wishlistItem => wishlistItem.id === item?.id);
  //     setIsInWishlist(isItemInWishlist);
  //   } else {
  //     // For logged-in users, you can call an API to check the wishlist status
  //     // Example: Check if item is in the server-side wishlist
  //     // You may replace this with an API call if needed.
  //     setIsInWishlist(bool);
  //   }
  // }, [user, bool, item?.id]);
  useEffect(() => {
    if (user) {
      if (item?.contain_variants && notMatched) {
        setIsInWishlist(
          wishlistItems?.some(
            (wishlistItem) =>
              wishlistItem?.product_id === item?.id &&
              wishlistItem?.product_variant_id === selectedVariant
          )
        );
      } else {
        // For base products (no variants involved or matched)
        setIsInWishlist(
          wishlistItems?.some(
            (wishlistItem) =>
              wishlistItem?.product_id === item?.id && !wishlistItem?.product_variant_id
          )
        );
      }
    }
  }, [user, wishlistItems, item?.id, selectedVariant, bool]);


  // useEffect(() => {
  //   if (!user) {
  //     const isItemInWishlist = wishlistItems?.some(wishlistItem => wishlistItem?.id === item?.id);
  //     setIsInWishlist(isItemInWishlist);
  //   }
  // }, [wishlistItems, item?.id]);

  useEffect(() => {
    if (!user) {
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      const isItemInWishlist = storedWishlist.some((wishlistItem) => {
        if (item?.contain_variants) {
          return (
            wishlistItem.id === item?.id &&
            wishlistItem.product_variant_id === selectedVariant
          );
        }
        return wishlistItem.id === item?.id;
      });
      setIsInWishlist(isItemInWishlist);
    }
  }, [user, item?.id, selectedVariant]);

  // Add item to wishlist (for guest users, store in localStorage)
  const addToWishlist = async () => {
    try {
      setLoading(true);

      if (!user) {
        // For guest users, store product details in localStorage
        const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        const newItem = {
          id: item?.id,
          name: item?.name,
          variantId: selectedVariant, // Include the variant ID
          category: item?.category?.name,
          childcategory: item?.childCategoryName,
          subcategory: item?.sub_category?.name,
          brand_name: item?.brand_name,
          price: item?.MRP,
          image: item?.image_urls[0], // Ensure image URL is stored
          discountedPrice: item?.discountedPrice,
          MRP: item?.MRP,
          discountPercentage: calculateDiscountPercentage(item),
          weight: item?.weight,
          width: item?.width,
          length: item?.length,
          height: item?.height,
          contain_variants: notMatched ? item?.contain_variants : false,
          product_variants: item?.product_variants,
          product_variant_id: selectedVariant, // Store the variant ID for local
          inStock: item?.inStock,
        };

        storedWishlist.push(newItem);
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

        setIsInWishlist(true); // Set state to true after adding to the wishlist
        toast.success("Added to wishlist (guest user)");

        setLoading(false);
        getUserWishlistItems();
        return;
      }

      // console.log("Product :- ", wishlistItems)

      // For logged-in users, use API
      const apiEndpoint = item?.contain_variants && notMatched
        ? `/api/wishlist/add/${item?.id}/${selectedVariant}`
        : `/api/wishlist/add/${item?.id}`;

      await get(apiEndpoint);


      // await get(`/api/wishlist/add/${item?.id}${item?.contain_variants ?  `` : ''}`);

      setIsInWishlist(true); // Set state to true after adding to the wishlist
      toast.success("Added to wishlist");
      setLoading(false);
      getUserWishlistItems(); // Refresh wishlist from the server
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error || "Something went wrong");
    }
  };

  // Remove item from wishlist (for guest users, remove from localStorage)
  const removeFromWishlist = async () => {
    try {
      setLoading(true);

      if (!user) {
        // Guest user logic
        let storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
        storedWishlist = storedWishlist.filter((wishlistItem) => {
          if (item?.contain_variants) {
            return !(
              wishlistItem.id === item?.id &&
              wishlistItem.product_variant_id === selectedVariant
            );
          }
          return wishlistItem.id !== item?.id;
        });
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
        setIsInWishlist(false);
        toast.success("Removed from wishlist (guest user)");
        setLoading(false);
        return;
      }

      const variantToRemove = item?.contain_variants && notMatched
        ? wishlistItems.find(
          (wishlistItem) =>
            wishlistItem.product_id === item?.id &&
            wishlistItem.product_variant_id === selectedVariant
        )
        : null;

      if (item?.contain_variants && !variantToRemove?.id && notMatched) {
        console.error("Variant not found in wishlist for removal");
        toast.error("Error removing item. Please refresh and try again.");
        setLoading(false);
        return;
      }

      // API call to remove the item
      await get(
        item?.contain_variants && notMatched
          ? `/api/wishlist/remove/${bool?.id}`
          : `/api/wishlist/remove/${bool?.id}`
      );

      console.log("Removing Bool", bool)

      console.log("Consolling Id Variant", variantToRemove?.id)

      console.log("Consolling Id Bool", bool?.id)

      setIsInWishlist(false);
      getUserWishlistItems();
      toast.success("Removed from wishlist");
    } catch (e) {
      console.error("Error removing from wishlist:", e);
      toast.error(e?.response?.data?.error || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  // Decide whether to add or remove the item from wishlist
  function callFunctionAccordingly(event) {
    event.stopPropagation();
    if (isInWishlist) {
      removeFromWishlist();
    } else {
      addToWishlist();
    }
  }

  // console.log("Wishlist True or False", isInWishlist)
  return isInWishlist ? (
    <PositionAbsolute onClick={callFunctionAccordingly}>
      <FavoriteIcon style={{ color: "rgb(218, 72, 72)" }} /> {/* Red when added to wishlist */}
    </PositionAbsolute>
  ) : (
    <PositionAbsolute onClick={callFunctionAccordingly}>
      <FavoriteBorderOutlinedIcon style={{ color: "black" }} /> {/* Black when not in wishlist */}
    </PositionAbsolute>
  );
}

export default ProuductShowCase;
