import React, { useEffect, useState, useRef } from "react";

import { post } from "../../api_helper/api_helper";
import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";
import IndividualCategory from "../../components/Category/IndividualCategory";
import DisplaySubCategory from "../../components/Category/SubCategory";
import { useSelector } from "react-redux";
import { setUserDetails } from "../../store/reducer/reducer";
import { useDispatch} from "react-redux";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "slick-carousel/slick/slick.css";
import { get } from "../../api_helper/api_helper";
import subcategory1 from '../../assets/images/subcategory_1.jpg';
import subcategory2 from '../../assets/images/subcategory_2.jpg';
import subcategory3 from '../../assets/images/subcategory_3.jpg';
import subcategory5 from '../../assets/images/subcategory_5.png';
import subcategory6 from '../../assets/images/subcategory_6.png';
import categories1 from "../../assets/images/categories_1.png"
import categories2 from "../../assets/images/categories_2.png"
import categories3 from "../../assets/images/categories_3.png"

import categories5 from "../../assets/images/categories_5.png"
import categories6 from "../../assets/images/categories_6.png"
import { useNavigate } from 'react-router-dom';

import "slick-carousel/slick/slick-theme.css";
import SingleProduct from "../../components/LandingPage/SingleProduct";

import { useMediaQuery } from "@mui/material";
import "./category.css";
import RecentlyProduct from "../../components/LandingPage/RecentlyProduct";
import shipora_img from "../../assets/images/SHIPORA.png"
const WidthAdjuster = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
  max-width:1525px;
  @media (max-width: 600px) {
    width: 97%;
  }
`;



const ViewCenter = styled.div`
  display: flex;
  margin: 50px auto;
  justify-content: center;
  align-items: center;
`;
const HeadingWithLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const Heading = styled.div`
  font-family: "Inter";
  font-weight: 600;
  font-size: 24px;
  color:#3E4D50;
  line-height: 29.05px;
  @media (max-width: 600px) {
    font-size: 19px;
  line-height: 24.05px;
  }
`;
const ButtonWrapper = styled.div`
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  
`;

const CarouselButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
 
 width: 6%;

 
  @media (max-width: 600px) {
    width: 20% !important;

  }

    @media (max-width: 1800px) {
    width: 6%;

  }

   @media (max-width: 1271px) {
    width: 11%;

  }

  
   @media (max-width: 990px) {
    width: 15%;

  }

     @media (max-width: 400px) {
    width: 22% !important;

  }
`;



const SliderWrapper = styled.div`
  margin-top: 40px;
  margin:auto;
`;
function Categories() {
  const [categories, setCategories] = useState([]);
  const [CategoriesImages, setCategoriesImages] = useState([]);
  const dispatch = useDispatch();


  const [indexTill, setIndexTill] = useState(9);
  const selectedCategoryFromLandingPage = useSelector(state => state.general.selectedCategoryFromLandingPage)
  const [viewAllClicked, setViewAllClicked] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(selectedCategoryFromLandingPage)
  const [products, setproducts] = useState([]);
  const [wishlistItems, setWishlistItems] = useState([]);
  const user = useSelector((state) => state.general.user);
  const navigate = useNavigate(); // Initialize the navigate function
  const RecentlyViewed = JSON.parse(localStorage.getItem('RecentlyViewed')) || [];
  // const [ads, setAds] = useState([]);
  // const [sliderImages, setSliderImages] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component is mounted
  }, []);
  async function getAllCategories() {
    try {
      const categories = await post("/api/categories");
      setCategories(categories.categories);
      setCategoriesImages(categories?.images)
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getAllCategories();
  }, []);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { userProfile } = await get("/api/auth/profile-info");
        dispatch(setUserDetails(userProfile || {}));
      } catch (e) {
        console.error("Error fetching user details:", e);
      }
    };

    fetchUserDetails();
  }, [dispatch]);	
  // console.log("Images Of Categories",CategoriesImages)

  async function getLandingPageDetails() {
    try {
      const data = await get("/api/ecom-dashboard/web");
      // setSliderImages(data?.sliderImages || []);
      // setAds(data?.sliderAds || []);
      setproducts(data?.propularProducts || []);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getLandingPageDetails();

  }, []);

  async function getUserWishlistItems() {
    if (user) {
      // Fetch wishlist items from API for logged-in users
      try {
        const items = await post("/api/wishlist", {});
        console.log("Fetched wishlist items (logged-in user):", items?.wishlist?.wishlist_items);
        setWishlistItems(items?.wishlist?.wishlist_items || []);
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    } else {
      // Fetch wishlist items from local storage for guest users
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      console.log("Fetched wishlist items (guest user):", storedWishlist);
      setWishlistItems(storedWishlist);
    }
  }


  // useEffect(() => {
  //   console.log("PopularProductSlider: wishlistItems updated:", wishlistItems);
  // }, [wishlistItems]);


  useEffect(() => {
    // Call this on initial render to load wishlist items
    getUserWishlistItems();
  }, []); // Run only once on mount

  useEffect(() => {
    console.log("ProductSlider: wishlistItems updated:", wishlistItems);
  }, [wishlistItems]);

  const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 16px;
  margin: 50px auto;

  @media (max-width: 768px) {
    flex-direction: column; // Change to column on smaller screens
  }
`;

  const RightColumn = styled.div`
  flex: 0 0 50%; // Takes up 50% of the width

  @media (max-width: 768px) {
    flex: 0 0 100%; // Takes full width on smaller screens
  }

  div {
    width: 100%;
      aspect-ratio: 16 / 13.1;
    border-radius: 20px;
    overflow: hidden; // Hide overflow to maintain shape

    img {
      width: 100%; // Ensure image takes full width
      height: 100%; // Maintain aspect ratio
      border-radius: 20px; // Match border radius
      object-fit:cover;
    }
  }
`;

  const LeftColumn = styled.div`
  // flex: 0 0 51.4%; 
    flex: 0 0 49.4%; 
  display: flex;
  flex-direction: column;
  gap:10px; 
  

  @media (max-width: 768px) {
    flex: 0 0 100%; // Takes full width on smaller screens
  }

  div {
    width: 100%;
aspect-ratio: 16 / 6.5;
    border-radius: 20px;
    overflow: hidden; // Hide overflow to maintain shape

    img {
      width: 100%; // Ensure image takes full width
      height: 100%; // Maintain aspect ratio
      border-radius: 20px; // Match border radius
      object-fit: cover; // Cover the container while maintaining aspect ratio
    }
  }
`;


  const Column = styled.div`
flex: 0 0 50%; // Takes up 50% of the width

@media (max-width: 768px) {
  flex: 0 0 100%; // Takes full width on smaller screens
}

div {
  width: 100%;
aspect-ratio: 16 / 6.5;
  border-radius: 20px;
  overflow: hidden; // Hide overflow to maintain shape
}

img {
  width: 100%; // Ensure image takes full width
  height: 100%; // Maintain aspect ratio
  border-radius: 20px; // Match border radius
       object-fit: cover; // Cover the container while maintaining aspect ratio
}
`;



const matches = useMediaQuery("(max-width:600px)");
const sliderRef = useRef(null);
const [currentSlide, setCurrentSlide] = useState(0);

// Slider settings
const settings = {
  dots: false,
  infinite: false, // Disable infinite looping
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  afterChange: (index) => setCurrentSlide(index), // Track the current slide
  responsive: [
    {
      breakpoint: 1479,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1036,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};



const slidesToShow =
  settings.responsive.find((resp) => window.innerWidth <= resp.breakpoint)
    ?.settings.slidesToShow || settings.slidesToShow;

const next = () => {
  sliderRef.current?.slickNext();
};

const previous = () => {
  sliderRef.current?.slickPrev();
};

const totalSlides = RecentlyViewed.length;
console.log("Current Slide Show", currentSlide)
console.log("Slide to show",slidesToShow)
console.log("Total Slides", totalSlides)

console.log("Details of Products", RecentlyViewed)

  return (
    <WidthAdjuster>
      {/* New Flexbox Container for Placeholders Above the Heading */}
      <FlexContainer>
        {/* Right column with one placeholder */}
        <RightColumn>
          <div>
            <img src={ `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${CategoriesImages[0]}` } alt="Category Image" />

          </div>
        </RightColumn>

        {/* Left column with two placeholders */}
        <LeftColumn>
          <div>
            <img src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${CategoriesImages[1]}`} alt="Category Image" />
          </div>
          <div>
            <img src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${CategoriesImages[2]}`} alt="Category Image" />
          </div>
        </LeftColumn>
      </FlexContainer>

      {/* Heading for All Categories */}

      <div style={{ marginTop: "0px", margin: "auto" }}>
        <Heading style={{ textAlign: "center" }}>All Categories</Heading>
        {/* <img src="subcategory_1.jpg.jpg"/> */}

        <Grid container spacing={2} alignItems="center" margin={"10px 0"} width={"100%"} style={{ cursor: "pointer" }}>
          {categories.slice(0, indexTill).map((item) => {
            return (
              <Grid item xs={6} md={4} key={item.id} style={{ paddingLeft: "0" }} onClick={() => {
                setSelectedCategory(item); // Set the selected category
                navigate("/sub-categories", { state: { selectedCategory: item } }); // Navigate to the sub-categories route
              }}>
                <IndividualCategory name={item?.name} img={item?.image_url} products={products} />
              </Grid>
            );
          })}
        </Grid>
      </div>


      {!viewAllClicked && categories.length > 9 && (
        <ViewCenter>
          <Button
            onClick={() => {
              setViewAllClicked(true);
              setIndexTill(categories.length);
            }}
            style={{
              borderRadius: "8px",
              width: "138px",
              height: "49px",
              color: "white",
              background: "#00B4D8",
            }}
          >
            View All
          </Button>
        </ViewCenter>
      )}


      <FlexContainer>
        {/* Right Column for One Placeholder */}
        <Column>
          <div>
            <img src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${CategoriesImages[3]}`} alt="Category Image" />
          </div>
        </Column>

        {/* Left Column for One Placeholder */}
        <Column>
          <div>
            <img src={`${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${CategoriesImages[4]}`} alt="Category Image" />
          </div>
        </Column>
      </FlexContainer>


      <div style={{ margin: "50px auto" }}>

        <HeadingWithLink>

          {RecentlyViewed.length > 0 &&    <Heading> Recently Viewed Products</Heading>}
       
          <CarouselButtonWrapper>
          {/* Left Arrow */}
          <ButtonWrapper
            onClick={previous}
            style={{ visibility: currentSlide === 0 ? "hidden" : "visible" }}
          >
            <ArrowBackIosIcon
              style={
                matches
                  ? { fontSize: "12px", color: "#595959", paddingLeft: "10px" }
                  : { fontSize: "16px", color: "#595959", paddingLeft: "6px" }
              }
            />
          </ButtonWrapper>

          {/* Right Arrow */}
          <ButtonWrapper
            onClick={next}
            style={{
              visibility:
                currentSlide >= totalSlides - slidesToShow
                  ? "hidden"
                  : "visible",
            }}
          >
            <ArrowForwardIosIcon
              style={
                matches
                  ? { fontSize: "12px", color: "#595959" }
                  : { fontSize: "16px", color: "#595959" }
              }
            />
          </ButtonWrapper>
        </CarouselButtonWrapper>
        </HeadingWithLink>
        <SliderWrapper>
          <Slider ref={sliderRef} {...settings}>
            {RecentlyViewed.map(item => {
              return <RecentlyProduct key={item.id} product={item} getUserWishlistItems={getUserWishlistItems} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} />
            })}
          </Slider>
        </SliderWrapper>
      </div>


    </WidthAdjuster>




  );
}

export default Categories;
