import React from "react";
import styled from "@emotion/styled";
import AirplanemodeActiveTwoToneIcon from "@mui/icons-material/AirplanemodeActiveTwoTone";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { setGrandShippingValue } from "../../store/reducer/reducer";
import { useDispatch } from 'react-redux';
import { formatDollars } from "../../helper/generalFunctions";
import { Box, Card, CardContent, Typography, Divider } from '@mui/material';

const Heading = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
    color:#3E4D50;
  line-height: 24.2px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const Cardd = styled.div`
  background: white;
  width: 134px;
  height: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px auto;
  box-sizing: border-box;
  border-radius: 4px;
`;
const CardSubText = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  margin: 20px;
`;
const CardPrice = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: center;
  margin: 5px;
`;
const CardBtn = styled.div`
  width: 109px;
  height: 32px;
  border-radius: 10px;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 24.2px;
  margin: 5px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SellingPrice = styled.div`
  font-family: "Inter";
  font-size: 32px;
  font-weight: 500;
  color: #00B4D8;
  line-height: 38.73px;

     @media (max-width: 600px) {
     font-size: 20px;
  }
`;

const MRP = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  margin-left: 10px;
  text-decoration: line-through;
  color: #999999;

  
     @media (max-width: 600px) {
     font-size: 17px;
  }
`;

const Discount = styled.div`
  color: #da4848;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  margin-left: 10px;

      @media (max-width: 600px) {
     font-size: 14px;
  }
`;
const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
`;

function TypeOfShipment({ shippingDetails, setTypeOfShipment, typeOfShipment, discountedPrice, qty, finalProduct, productSelected, setQty,
  selectedVariant }) {
  const dispatch = useDispatch();
  const selectedVariantDetails = finalProduct?.product_variants?.find(
    (variant) => variant.id === selectedVariant
  );

  const grandShippingValue = useSelector(
    (state) => state.general.grandShippingValue
  );





  console.log("Check varientss shipment page", typeOfShipment)
  // console.log("Check the Grand Shipping Value")

  // const price= shippingDetails?.amountShip ? formatDollars(shippingDetails?.amountShip) : "--"


  // Define the parseCurrency function here
  const parseCurrency = (value) => {
    // Ensure value is a string before using replace
    if (typeof value === "string") {
      return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
    }
    // If value is already a number, return it as is
    if (typeof value === "number") {
      return value;
    }
    // In case value is undefined or null, return 0
    return 0;
  };

  const airPrice = shippingDetails?.amountAir ? parseCurrency(shippingDetails?.amountAir) : 0;
  const shipPrice = shippingDetails?.amountShip ? parseCurrency(shippingDetails?.amountShip) : 0;
  const calculateDiscountPercentage = () => {
    const priceToUse = selectedVariantDetails
      ? selectedVariantDetails.discountedPrice
      : finalProduct.discountedPrice;
    const mrpToUse = selectedVariantDetails ? selectedVariantDetails.MRP : finalProduct.MRP;

    if (priceToUse && mrpToUse) {
      const discountedPrice = parseFloat(priceToUse);
      const mrp = parseFloat(mrpToUse);
      const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
      return parseFloat(discountPercentage.toFixed(2));
    }
    return 0;
  };
  const preMRP = finalProduct?.MRP;
  // console.log("MRP",preMRP)
  // Ensure these are numbers
  const discountedPriceValue = Number(discountedPrice) || 0; // Ensure discountedPrice is a number
  const quantity = Number(qty) || 0
  // const priceValue = parseCurrency(price) || 0; // Use parseCurrency to get the numeric value
  // const MRP= Number(preMRP) || 0;

  // const totalPrice = selectedVariantDetails ? selectedVariantDetails?.discountedPrice + priceValue : discountedPriceValue  + priceValue;

  // const totalPrice = selectedVariantDetails
  //   ? selectedVariantDetails?.discountedPrice + (typeOfShipment === "AIR" ? airPrice : shipPrice)
  //   : discountedPriceValue + (typeOfShipment === "AIR" ? airPrice : shipPrice);

  const totalPrice = selectedVariantDetails
    ? selectedVariantDetails?.discountedPrice +
    (typeOfShipment === "AIR"
      ? airPrice
      : typeOfShipment === "SHIP"
        ? shipPrice
        : 0)
    : discountedPriceValue +
    (typeOfShipment === "AIR"
      ? airPrice
      : typeOfShipment === "SHIP"
        ? shipPrice
        : 0);

  // const varianttotalprice=selectedVariantDetails?.discountedPrice + priceValue;
  // console.log("Total Price:", totalPrice); 

  // console.log("Check Value",grandShippingValue)

  useEffect(() => {
    const selectedPrice = typeOfShipment === 'AIR' ? airPrice : shipPrice;
    const totalShipping = selectedPrice * qty;

    dispatch(setGrandShippingValue(totalShipping));
  }, [qty, typeOfShipment, airPrice, shipPrice, dispatch]);


  return (
    <>
      <Heading>Type of Shipment</Heading>
      <Grid container spacing={2}>
        <Grid item alignItems={"center"} style={{ cursor: "pointer" }}>
          <DisplayShipmentCard
            iconName="Airplane"
            priceValue={airPrice}
            selected={typeOfShipment}
            icon="AIR"
            name={"AIR"}
            duration={shippingDetails?.durationAir}
            setSelected={setTypeOfShipment}
            discountedPrice={discountedPrice}
            quantity={quantity}
          />
        </Grid>
        <Grid item alignItems={"center"} justifyContent={"center"} style={{ cursor: "pointer" }}>
          <DisplayShipmentCard
            iconName="Ship"
            priceValue={shipPrice}
            selected={typeOfShipment}
            icon="SHIP"
            name={"SHIP"}
            duration={shippingDetails?.durationShip}
            setSelected={setTypeOfShipment}
            discountedPrice={discountedPrice}
            quantity={quantity}
          />
        </Grid>
      </Grid>

      {/* <h1>{`${discountedPriceValue * quantity}$ | ${priceValue *quantity}$ | ${totalPrice * quantity}`}$</h1> */}

      {typeOfShipment ? <> <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Box
          sx={{
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)', // Box shadow instead of border
            borderRadius: '8px',
            padding: '20px 16px',
            display: 'inline-block',
            width: '367px',
            '@media (max-width: 500px)': {
              padding: '20px 0px !important', // Adjust padding when screen width is 430px or less
            },

            '@media (max-width: 500px)': {
              width: 'fit-content',

            },

          }}
        >
          <Grid container alignItems="center">
            <Grid item xs style={{ padding: "0px 0px" }}>
              <Typography variant="body2" align="center" color="textSecondary" sx={{
                fontSize: '16px',
                fontWeight: '500',
                fontFamily: "Inter",
                color: '#424545',
                '@media (max-width: 500px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
                Produce   Price
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: '#838484',
                fontFamily: "Inter",
                '@media (max-width: 500px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
                {selectedVariantDetails ? formatDollars(selectedVariantDetails?.discountedPrice * quantity) : formatDollars(discountedPriceValue * quantity)}
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item xs style={{ padding: "0px 0px" }}>
              <Typography variant="body2" align="center" color="textSecondary" sx={{
                fontSize: '16px',
                fontWeight: '500',
                fontFamily: "Inter",
                color: '#424545',
                '@media (max-width: 500px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
                {typeOfShipment === "AIR" ? "Airplane Price" : "Ship Price"}
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                fontFamily: "Inter",
                color: '#838484',
                '@media (max-width: 500px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
                {/* {typeOfShipment === "AIR"
                  ? formatDollars(airPrice * quantity)
                  : formatDollars(shipPrice * quantity)
                } */}

                {
                  typeOfShipment === "AIR"
                    ? ` ${formatDollars(airPrice * quantity)}`
                    : typeOfShipment === "SHIP"
                      ? ` ${formatDollars(shipPrice * quantity)}`
                      : formatDollars(0)
                }
              </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ height: 'auto', marginLeft: '8px', marginRight: '8px' }} />

            <Grid item xs style={{ padding: "0px 0px" }}>
              <Typography variant="body2" align="center" color="textSecondary" sx={{
                fontSize: '16px',
                fontWeight: '500',
                color: '#424545',
                fontFamily: "Inter",
                '@media (max-width: 500px)': {
                  fontSize: '13px', // Apply font-size 11px on max-width 393px
                },
              }}>
                Total Price
              </Typography>
              <Typography variant="h6" align="center" sx={{
                fontSize: '15px',
                fontWeight: '500',
                color: '#838484',
                fontFamily: "Inter",
                '@media (max-width: 500px)': {
                  fontSize: '11px', // Apply font-size 11px on max-width 393px
                },
              }}>
                {formatDollars(totalPrice * quantity)}
              </Typography>
            </Grid>


          </Grid>
        </Box>
      </div>

        <PriceContainer>
          {finalProduct?.discountedPrice ? (
            <>
              <SellingPrice>
                {formatDollars(totalPrice * quantity)}
              </SellingPrice>
              <MRP>{selectedVariantDetails ? formatDollars(selectedVariantDetails?.MRP * quantity) : formatDollars(preMRP * quantity)}</MRP>
              <Discount>{calculateDiscountPercentage()}% OFF</Discount>
            </>
          ) : (
            <>
              <SellingPrice>{formatDollars(finalProduct?.MRP)}</SellingPrice>
            </>
          )}
        </PriceContainer>
      </>


        : null}


    </>
  );
}

function DisplayShipmentCard({
  iconName,
  icon,
  name,
  duration,
  selected,
  setSelected,
  priceValue,
  quantity,
  discountedPrice,
}) {
  const Icon = icon === "AIR" ? AirplanemodeActiveTwoToneIcon : DirectionsBoatFilledOutlinedIcon;
  const cardColor = selected === name ? `#03BEE7` : `#838484`;
  const textColor = selected === name ? `#242626` : `#838484`;
  const btnTextColor = selected === name ? `white` : `#838484`;
  const btnBackgroundColor = selected === name ? `#03BEE7` : `#E3E3E3`;

  console.log("hello selected", selected)



  return (
    <>
      <Cardd
        style={{
          border: selected === name ? `1.5px solid #03BEE7` : "1.5px solid #E3E3E3",
        }}
        onClick={() => {
          setSelected(name);
          // console.log("H",name)
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Icon style={{ color: cardColor, fontSize: "25px", fontWeight: 500 }} />
          <div style={{ color: cardColor, marginLeft: "5px", fontSize: "16px", fontWeight: 500, fontFamily: "Inter" }}>
            {iconName}
          </div>
        </div>
        <CardPrice style={{ color: cardColor }}>{formatDollars(priceValue * quantity) || "--"}</CardPrice>
        <CardBtn style={{ color: btnTextColor, background: btnBackgroundColor }}>
          {duration || "--"} Days
        </CardBtn>
      </Cardd>

      {/* <h1>{`${discountedPriceValue} | ${priceValue} | ${totalPrice}`}</h1> */}
    </>
  );
}

export default TypeOfShipment;
