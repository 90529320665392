import React from "react";
import styled from "@emotion/styled";
import Cartitem from "./Cartitem";
import empty_order from "../../../src/assets/images/Group33257.png"

const OrderWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 4px 30px 0px #42424229;
  border-radius: 16px;
  background: white;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

function OrderBox({
  orders,
  setDisplayModal,
  setSelectedOrder,
  setDisplayModalOrderInfo,
  selectedOrder,

  
}) {
  return (
    <OrderWrapper>
      {orders?.length === 0 ? (
        <>
          <div style={{display : 'flex',flexDirection : 'column', alignItems : "center",justifyContent : "center",height : "500px"}}>
            <img src={empty_order} />
            <p style={{ fontFamily: "Inter", color: "#7B7B7B" }}>
              You Don't have any order
            </p>
          </div>
        </>
      ) : (
        orders.map((item) => (
          <Cartitem
            key={item.id}
            setDisplayModalOrderInfo={setDisplayModalOrderInfo}
            setSelectedOrder={setSelectedOrder}
            item={item}
            setDisplayModal={setDisplayModal}
            selectedOrder={selectedOrder}

          />
        ))
      )}
    </OrderWrapper>
  );
}

export default OrderBox;
