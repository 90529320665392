import React from "react";
import styled from "@emotion/styled";
import { formatDollars } from "../../helper/generalFunctions";
import NoImage from "../../assets/images/no-image.jpeg"

const CartItemBox = styled.div`
  /* width: 100%; */
  min-height: 127px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  /* margin: 20px auto;
  border-radius: 8px; */
`;

const ImageBox = styled.div`
  width: 180px;
  height: 162px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f8f8;
  @media (max-width: 800px) {
    width: 150px;
    height: 120px;
  }

    @media (max-width: 400px) {
    width: 150px;
    height: 80px;
  }

   img {
    width: 60%;

    @media (max-width: 800px) {
      width: 75%; // Adjust the width for smaller screens
    }
  }
`;

const ItemDetailsBox = styled.div`
  display: flex;

  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  flex: 2;
  @media (max-width: 800px) {
    padding: 10px;
  }
`;

const ItemHeading = styled.div`
  font-family: "Inter";
  font-size: 20px;
  
  font-weight: 400;
  // line-height: 29.05px;
  line-height:20.05px;
  color: #323232;
  width: 70%;

  @media (max-width: 800px) {
    // margin-bottom: 7px;
    line-height: 15px;
    font-size: 14px;
    /* max-height: 500px;
    overflow-x: auto; */
  }
`;

const ProductvariantDetails = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  color: #838484;

  @media (max-width: 800px) {
    font-size: 11px;
  }
`;
const PriceAndCartBtnsBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
margin-top: ${(props) => (props.containVariants ? "0" : "10px")};
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
const PriceBox = styled.div`
  display: flex;
  justify-content:center;
  align-items:center;
`;

const SellingPrice = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  color: #000000;
  @media (max-width: 800px) {
    font-size: 15px;
  }
`;
const MRP = styled.div`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  text-decoration: line-through;
  color: #9a9a9a;
  margin-left: 5px;
  @media (max-width: 800px) {
    font-size: 15px;
  }
`;
const OrderDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30%;
`;

const OrderId = styled.span`
  color: #999999;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

const TrackBtn = styled.button`
  border: none;
  outline: none;
  background: #defaff;
  width: 285px;
  height: 40px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #19889f;
  cursor: pointer;

  @media (max-width: 800px) {
    width: 150px;
    font-size: 13px;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
const ViewOrderBtn = styled.button`
  border: none;
  outline: none;
  background: white;
  width: 285px;
  height: 40px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #7b7b7b;
  line-height: 20px;
  color: #454545;
  margin-top: 10px;
  cursor: pointer;
  @media (max-width: 800px) {
    width: 150px;
    font-size: 13px;
  }
`;

const Color = styled.div`
  font-family: "Inter";
  font-size: 17px;
  font-weight: 400;
  line-height: 36px;
  text-align: justify;
  color:#838484;
    @media (max-width: 800px) {
     font-size: 14px;
  }

  
`;

const Discount = styled.div`
  color: #da4848;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  margin-left: 10px;

     @media (max-width: 600px) {
     font-size: 14px;
  }

  
    @media (max-width: 919px) {
    font-size: 17px;

  }

    @media (max-width: 800px) {
    font-size: 15px;
  }

        @media (min-width: 800px) and (max-width: 1041px) {
  font-size: 17px;

  }

      @media (max-width: 1105px) {
margin-left:0;
  }

  
  
    @media (max-width: 463px) {
    margin-left:3px;

    font-size: 9px;
    line-height:unset;

  }
`;

function OrderCartitem({
  setDisplayModalOrderInfo,
  item,
  setDisplayModal,
  setSelectedOrder,
}) {
  const finalProduct = item?.product_variant
    ? { ...item?.product, ...item?.product_variant }
    : item?.product;

    console.log("Checking the details of product",finalProduct)

    const calculateDiscountPercentage = () => {
      if (finalProduct?.discountedPrice && finalProduct?.MRP) {
        const discountedPrice = parseFloat(finalProduct.discountedPrice);
        const mrp = parseFloat(finalProduct.MRP);
        const discountPercentage = ((mrp - discountedPrice) / mrp) * 100;
          return parseFloat(discountPercentage.toFixed(2));
      }
      return 0;
    };

  function getVariantTypeString() {
    if (item?.product?.contain_variants) {
      let str = ``;
      item?.product_variant?.attrbute_value?.forEach((item) => {
        let attributeName = item?.attribute?.name;
        str += `${attributeName || ""}/${item.value || ""}   `;
      });
      return str;
    } else {
      return "";
    }
  }

  console.log("Details of Final Prod:-", finalProduct)
  return (
    <CartItemBox>
      <ImageBox>
        <img
    
          alt="cart-item"
          src={finalProduct?.image_urls?.length
            ? `${process.env.REACT_APP_AUTHDOMAIN}/api/uploads/${finalProduct?.image_urls[0]}`
            : NoImage}
        />
      </ImageBox>

      <ItemDetailsBox>
        <ItemHeading>{finalProduct?.name}</ItemHeading>
        {/* <ProductvariantDetails>{getVariantTypeString()}</ProductvariantDetails> */}
       { finalProduct?.contain_variants ? finalProduct?.color && <Color>Color:{finalProduct?.color ? finalProduct?.color : "none"}</Color> : null}
        <PriceAndCartBtnsBox containVariants={finalProduct?.color}>
          {finalProduct?.discountedPrice ? (
            <PriceBox>
              <SellingPrice>
                {formatDollars(finalProduct?.discountedPrice)}
              </SellingPrice>
              <MRP>{formatDollars(finalProduct?.MRP)}</MRP>
              <Discount>{calculateDiscountPercentage()}% off</Discount>
            </PriceBox>
          ) : (
            <PriceBox>
              <SellingPrice>{formatDollars(finalProduct?.MRP)}</SellingPrice>
            </PriceBox>
          )}
        </PriceAndCartBtnsBox>
      </ItemDetailsBox>
    </CartItemBox>
  );
}

export default OrderCartitem;
